// CREATE VERSION CONTROL
export const CREATE_VERSION_CONTROL_REQUESTED = "CREATE_VERSION_CONTROL_REQUESTED";
export const CREATE_VERSION_CONTROL_SUCCESS = "CREATE_VERSION_CONTROL_SUCCESS";
export const CREATE_VERSION_CONTROL_FAILED = "CREATE_VERSION_CONTROL_FAILED";

// GET VERSION CONTROL DETAILS
export const GET_VERSION_CONTROL_DETAILS_REQUESTED = "GET_VERSION_CONTROL_DETAILS_REQUESTED";
export const GET_VERSION_CONTROL_DETAILS_SUCCESS = "GET_VERSION_CONTROL_DETAILS_SUCCESS";
export const GET_VERSION_CONTROL_DETAILS_FAILED = "GET_VERSION_CONTROL_DETAILS_FAILED";

// UPDATE VERSION CONTROL
export const UPDATE_VERSION_CONTROL_REQUESTED = "UPDATE_VERSION_CONTROL_REQUESTED";
export const UPDATE_VERSION_CONTROL_SUCCESS = "UPDATE_VERSION_CONTROL_SUCCESS";
export const UPDATE_VERSION_CONTROL_FAILED = "UPDATE_VERSION_CONTROL_FAILED";