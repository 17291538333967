import { call, put, debounce } from 'redux-saga/effects'
import instance from "../../../api/api_instance";

async function getApi(data) {
    const search = data.search.replace(/\s+/g, ' ').trim();
    try {
      const result = instance({
            url: `v1/model/${data.model_id}/anaglyph/parts/${data.id}/notes?search=${encodeURIComponent(search)}&limit=${data.limit}&page=${data.page + 1}`,
            method: "GET",
        }).then((response) => {
            return response;
        })
        return await result;
    } catch (error) {
        throw error;
    }
}

function* fetchPartNotes(action) {
    try {
        const res = yield call(getApi, action.payload);
        yield put({ type: 'GET_ALL_PART_NOTES_SUCCESS', allPartNotesList: res.data });
    } catch (e) {
        yield put({ type: 'GET_ALL_PART_NOTES_FAILED', message: e.message });
    }
}

function* getAllPartNotesSaga() {
    yield debounce(1000, 'GET_ALL_PART_NOTES_REQUESTED', fetchPartNotes);
}

export default getAllPartNotesSaga;