import { call, put, takeEvery } from 'redux-saga/effects';
import instance from "../../../api/api_instance";

async function getApi(data) {
  try {
    const result = instance({
      url: `v1/model/${data.id}`,
      method: "PUT",
      data: data
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}

function* createModels(action) {
  const data = {
    search: "",
    page: 0,
    limit: 10,
  }

  try {
    const res = yield call(getApi, action.payload);
    const toaseData = {
      content: `${res.data.model.title} model details updated Successfully`,
      type: "success"
    };
    yield put({ type: "UPDATE_MODEL_SUCCESS", allModels: res.data });
    yield put({ type: "GET_ALL_MODELS_REQUESTED", payload: data });
    yield put({ type: "SET_TOASTER_SUCCESS", data: toaseData });
    window.location.href = `/device-model/${action.payload.id}`

  } catch (e) {
    const toaseData = {
      content: `Failed to add the Model from Update`,
      type: "failed"
    };
    if (e.response.status == 406 || e.response.status == 404 || e.response.status == 422) {
      yield put({ type: "UPDATE_MODEL_FAILED", message: e.response.data.errors });
    }
    yield put({ type: "SET_TOASTER_SUCCESS", data: toaseData });
  }
}

function* updateModelsSaga() {
  yield takeEvery("UPDATE_MODEL_REQUESTED", createModels);
}

export default updateModelsSaga;