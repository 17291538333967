import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../api/api_instance";

async function getApi(data) {
  const postData = {
    linking: data.type
  }
  try {
    const result = instance({
      url: `v1/model/${data.model_id}/link_unlink_anaglyph`,
      method: "POST",
      data: postData
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}

function* unlinkAnaglyph(action) {
  const data = {
    model_id: action.payload.model_id,
    search: '',
    page: 0,
    limit: 10,
  }
  try {
    const res = yield call(getApi, action.payload);
    const toastrData = {
      content: `${action.payload.type === "enable" ? `${res.data.anaglyph.media.title} 3D file is Linked Successfully` : `${res.data.anaglyph.media.title} 3D file is Unlinked Successfully`}`,
      type: "success"
    };
    yield put({ type: 'UNLINK_ANAGLYPH_SUCCESS', anaglyphList: res.data });
    yield put({ type: 'GET_ALL_ANAGLYPH_REQUESTED', payload: data });
    yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
  } catch (e) {
    if (e.response.status === 406 || e.response.status === 404 || e.response.status === 422) {
      yield put({ type: "UNLINK_ANAGLYPH_FAILED", message: e.response.data });
    } else {
      yield put({ type: "UNLINK_ANAGLYPH_FAILED", message: "Some error occurred" });
    }
  }
}

function* unlinkAnaglyphSaga() {
  yield takeEvery('UNLINK_ANAGLYPH_REQUESTED', unlinkAnaglyph);
}

export default unlinkAnaglyphSaga;