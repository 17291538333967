import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../api/api_instance";

async function getApi(data) {
    try {
        const result = instance({
            url: `v1/model/${data.model_id}/safety_measure/${data.id}`,
            method: "DELETE"
        }).then((response) => {
            return response;
        })
        return await result;
    } catch (error) {
        throw error;
    }
}

function* deleteSafetyMeasure(action) {
    const data = {
        model_id: action.payload.model_id,
        search: "",
        page: 0,
        filter: {},
        limit: 10,
    }
    try {
        const res = yield call(getApi, action.payload);
        const toastrData = {
            content: `${res.data.title} Safety Measure deleted Successfully`,
            type: "success"
        }
        yield put({ type: 'DELETE_SAFETY_MEASURE_SUCCESS', safetyMeasuresList: res.data });
        yield put({ type: 'GET_ALL_SAFETY_MEASURES_REQUESTED', payload: data });
        yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
    } catch (e) {
        const toastrData = {
            content: "Failed to delete this Safety Measure",
            type: "failed"
        }
        if (e.response.status === 406 || e.response.status === 404) {
            yield put({ type: "DELETE_SAFETY_MEASURE_FAILED", message: e.response.data });
            yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
        } else {
            yield put({ type: "DELETE_SAFETY_MEASURE_FAILED", message: "Some error occurred" });
            yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
        }

        if (e.response.status === 500) {
            const toastrFailedData = {
              content: e.response.data.errors ? e.response.data.errors : "Something went wrong!",
              type: "failed"
            };
            yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
        }
    }
}

function* deleteSafetyMeasureSaga() {
    yield takeEvery('DELETE_SAFETY_MEASURE_REQUESTED', deleteSafetyMeasure);
}

export default deleteSafetyMeasureSaga;