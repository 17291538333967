//  GET ALL MODELS
export const GET_ALL_MODELS_REQUESTED = "GET_ALL_MODELS_REQUESTED";
export const GET_ALL_MODELS_SUCCESS = "GET_ALL_MODELS_SUCCESS";
export const GET_ALL_MODELS_FAILED = "GET_ALL_MODELS_FAILED";

// CREATE A MODEL
export const CREATE_MODEL_REQUESTED = "CREATE_MODEL_REQUESTED";
export const CREATE_MODEL_SUCCESS = "CREATE_MODEL_SUCCESS";
export const CREATE_MODEL_FAILED = "CREATE_MODEL_FAILED";

// UPDATE A MODEL
export const UPDATE_MODEL_REQUESTED = "UPDATE_MODEL_REQUESTED";
export const UPDATE_MODEL_SUCCESS = "UPDATE_MODEL_SUCCESS";
export const UPDATE_MODEL_FAILED = "UPDATE_MODEL_FAILED";

// GET MODEL DETAILS
export const MODEL_DETAILS_REQUESTED = "MODEL_DETAILS_REQUESTED";
export const MODEL_DETAILS_SUCCESS = "MODEL_DETAILS_SUCCESS";
export const MODEL_DETAILS_FAILED = "MODEL_DETAILS_FAILED";

// UPDATE A MODEL
export const MODEL_DEVICES_REQUESTED = "MODEL_DEVICES_REQUESTED";
export const MODEL_DEVICES_SUCCESS = "MODEL_DEVICES_SUCCESS";
export const MODEL_DEVICES_FAILED = "MODEL_DEVICES_FAILED";

// DELETE A MODEL
export const DELETE_MODEL_REQUESTED = "DELETE_MODEL_REQUESTED";
export const DELETE_MODEL_SUCCESS = "DELETE_MODEL_SUCCESS";
export const DELETE_MODEL_FAILED = "DELETE_MODEL_FAILED";

// RESET MODEL ERROR MESSAGES
export const RESET_MODEL_ERRORS_REQUESTED = "RESET_MODEL_ERRORS_REQUESTED";

// MODEL MODAL
export const SET_MODEL_MODAL = "SET_MODEL_MODAL";

// CHANGE SEARCH
export const CHANGE_MODELS_SEARCH_REQUESTED = "CHANGE_MODELS_SEARCH_REQUESTED";