
// POST LOGIN
export const POST_LOGIN_REQUESTED = 'POST_LOGIN_REQUESTED';
export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS';
export const POST_LOGIN_FAILED = 'POST_LOGIN_FAILED';

// LOGIN RESET MESSAGE
export const LOGIN_ERROR_RESET_REQUESTED = 'LOGIN_ERROR_RESET_REQUESTED';

// PRE LOGIN LOGO
export const GET_PRE_LOGIN_LOGO_REQUESTED = "GET_PRE_LOGIN_LOGO_REQUESTED";
export const GET_PRE_LOGIN_LOGO_SUCCESS = "GET_PRE_LOGIN_LOGO_SUCCESS";
export const GET_PRE_LOGIN_LOGO_FAILED = "GET_PRE_LOGIN_LOGO_FAILED";

// CONFIRM LOGIN OTP
export const CONFIRM_LOGIN_OTP_REQUESTED = "CONFIRM_LOGIN_OTP_REQUESTED";
export const CONFIRM_LOGIN_OTP_SUCCESS = "CONFIRM_LOGIN_OTP_SUCCESS";
export const CONFIRM_LOGIN_OTP_FAILED = "CONFIRM_LOGIN_OTP_FAILED";

// RESEND LOGIN OTP
export const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

// LOGOUT
export const RESEND_LOGIN_OTP_REQUESTED = "RESEND_LOGIN_OTP_REQUESTED";
export const RESEND_LOGIN_OTP_SUCCESS = "RESEND_LOGIN_OTP_SUCCESS";
export const RESEND_LOGIN_OTP_FAILED = "RESEND_LOGIN_OTP_FAILED";

// PERMISSION
export const GET_ALL_USER_PERMISSIONS_REQUESTED = "GET_ALL_USER_PERMISSIONS_REQUESTED";
export const GET_ALL_USER_PERMISSIONS_SUCCESS = "GET_ALL_USER_PERMISSIONS_SUCCESS";
export const GET_ALL_USER_PERMISSIONS_FAILED = "GET_ALL_USER_PERMISSIONS_FAILED";

// USER DETAILS
export const GET_USER_DETAILS_REQUESTED = "GET_USER_DETAILS_REQUESTED";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILED = "GET_USER_DETAILS_FAILED";

// UPDATE LAST PERMISSION GOT
export const GOT_WHEN_PERMISSION_UPDATED_REQUESTED = "GOT_WHEN_PERMISSION_UPDATED_REQUESTED";
export const GOT_WHEN_PERMISSION_UPDATED_SUCCESS = "GOT_WHEN_PERMISSION_UPDATED_SUCCESS";
export const GOT_WHEN_PERMISSION_UPDATED_FAILED = "GOT_WHEN_PERMISSION_UPDATED_FAILED";

// RESET_OTP_ERROR
export const RESET_OTP_ERROR = "RESET_OTP_ERROR";

// SET_LOGIN_STEP
export const SET_LOGIN_STEP = "SET_LOGIN_STEP";

// RESET LOGIN LOADING
export const RESET_LOGIN_LOADING_REQUESTED = "RESET_LOGIN_LOADING_REQUESTED";

// RESET login show counter
export const RESET_LOGIN_OTP_SHOW_COUNTER_REQUESTED = "RESET_LOGIN_OTP_SHOW_COUNTER_REQUESTED";
