// GET ALL MANUALS (sketch_type = 1) =================================
export const GET_ALL_MANUALS_REQUESTED = 'GET_ALL_MANUALS_REQUESTED';
export const GET_ALL_MANUALS_SUCCESS = 'GET_ALL_MANUALS_SUCCESS';
export const GET_ALL_MANUALS_FAILED = 'GET_ALL_MANUALS_FAILED';

// GET A MANUAL DETAIL (sketch_type = 1)
export const MANUAL_DETAILS_REQUESTED = 'MANUAL_DETAILS_REQUESTED';
export const MANUAL_DETAILS_SUCCESS = 'MANUAL_DETAILS_SUCCESS';
export const MANUAL_DETAILS_FAILED = 'MANUAL_DETAILS_FAILED';

// ADD A MANUALS (sketch_type = 1)
export const ADD_MANUAL_REQUESTED = 'ADD_MANUAL_REQUESTED';
export const ADD_MANUAL_SUCCESS = 'ADD_MANUAL_SUCCESS';
export const ADD_MANUAL_FAILED = 'ADD_MANUAL_FAILED';

// UPDATE A MANUAL (sketch_type = 1)
export const UPDATE_MANUAL_REQUESTED = 'UPDATE_MANUAL_REQUESTED';
export const UPDATE_MANUAL_SUCCESS = 'UPDATE_MANUAL_SUCCESS';
export const UPDATE_MANUAL_FAILED = 'UPDATE_MANUAL_FAILED';

// DELETE A MANUAL (sketch_typ = 1)
export const DELETE_MANUAL_REQUESTED = 'DELETE_MANUAL_REQUESTED';
export const DELETE_MANUAL_SUCCESS = 'DELETE_MANUAL_SUCCESS';
export const DELETE_MANUAL_FAILED = 'DELETE_MANUAL_FAILED';




//  GET ALL DRAWINGS (sketch_type = 2) ====================================
export const GET_ALL_DRAWINGS_REQUESTED = 'GET_ALL_DRAWINGS_REQUESTED';
export const GET_ALL_DRAWINGS_SUCCESS = 'GET_ALL_DRAWINGS_SUCCESS';
export const GET_ALL_DRAWINGS_FAILED = 'GET_ALL_DRAWINGS_FAILED';

// GET A DRAWING DETAIL (sketch_type = 2)
export const DRAWING_DETAILS_REQUESTED = 'DRAWING_DETAILS_REQUESTED';
export const DRAWING_DETAILS_SUCCESS = 'DRAWING_DETAILS_SUCCESS';
export const DRAWING_DETAILS_FAILED = 'DRAWING_DETAILS_FAILED';

// ADD A DRAWING (sketch_type = 2)
export const ADD_DRAWING_REQUESTED = 'ADD_DRAWING_REQUESTED';
export const ADD_DRAWING_SUCCESS = 'ADD_DRAWING_SUCCESS';
export const ADD_DRAWING_FAILED = 'ADD_DRAWING_FAILED';

// UPDATE A DRAWING (sketch_type = 2)
export const UPDATE_DRAWING_REQUESTED = 'UPDATE_DRAWING_REQUESTED';
export const UPDATE_DRAWING_SUCCESS = 'UPDATE_DRAWING_SUCCESS';
export const UPDATE_DRAWING_FAILED = 'UPDATE_DRAWING_FAILED';

// DELETE A DRAWING (sketch_type = 2)
export const DELETE_DRAWING_REQUESTED = 'DELETE_DRAWING_REQUESTED';
export const DELETE_DRAWING_SUCCESS = 'DELETE_DRAWING_SUCCESS';
export const DELETE_DRAWING_FAILED = 'DELETE_DRAWING_FAILED';




// GET ALL ANIMATIONS (sketch_type = 3) ====================================
export const GET_ALL_ANIMATIONS_REQUESTED = 'GET_ALL_ANIMATIONS_REQUESTED';
export const GET_ALL_ANIMATIONS_SUCCESS = 'GET_ALL_ANIMATIONS_SUCCESS';
export const GET_ALL_ANIMATIONS_FAILED = 'GET_ALL_ANIMATIONS_FAILED';

// GET AN ANIMATION DETAIL (sketch_type = 3)
export const ANIMATION_DETAILS_REQUESTED = 'ANIMATION_DETAILS_REQUESTED';
export const ANIMATION_DETAILS_SUCCESS = 'ANIMATION_DETAILS_SUCCESS';
export const ANIMATION_DETAILS_FAILED = 'ANIMATION_DETAILS_FAILED';

// ADD AN ANIMATION (sketch_type = 3)
export const ADD_ANIMATION_REQUESTED = 'ADD_ANIMATION_REQUESTED';
export const ADD_ANIMATION_SUCCESS = 'ADD_ANIMATION_SUCCESS';
export const ADD_ANIMATION_FAILED = 'ADD_ANIMATION_FAILED';

// UPDATE AN ANIMATION (sketch_type = 3)
export const UPDATE_ANIMATION_REQUESTED = 'UPDATE_ANIMATION_REQUESTED';
export const UPDATE_ANIMATION_SUCCESS = 'UPDATE_ANIMATION_SUCCESS';
export const UPDATE_ANIMATION_FAILED = 'UPDATE_ANIMATION_FAILED';

// DELETE A ANIMATION (sketch_type =3 )
export const DELETE_ANIMATION_REQUESTED = 'DELETE_ANIMATION_REQUESTED';
export const DELETE_ANIMATION_SUCCESS = 'DELETE_ANIMATION_SUCCESS';
export const DELETE_ANIMATION_FAILED = 'DELETE_ANIMATION_FAILED';


// SKETCHES MODAL
export const SET_MANUALS_MODAL = "SET_MANUALS_MODAL";
export const SET_DRAWINGS_MODAL = "SET_DRAWINGS_MODAL";
export const SET_ANIMATIONS_MODAL = "SET_ANIMATIONS_MODAL";
export const SET_UPDATE_GROUP_MODAL = "SET_UPDATE_GROUP_MODAL";


// RESET SKETCHES ERROR MESSAGES
export const RESET_MANUALS_ERRORS_REQUESTED = "RESET_MANUALS_ERRORS_REQUESTED";
export const RESET_DRAWINGS_ERRORS_REQUESTED = "RESET_DRAWINGS_ERRORS_REQUESTED";
export const RESET_ANIMATIONS_ERRORS_REQUESTED = "RESET_ANIMATIONS_ERRORS_REQUESTED";

// CHANGE SEARCH
export const CHANGE_SKETCHES_SEARCH_REQUESTED = "CHANGE_SKETCHES_SEARCH_REQUESTED";