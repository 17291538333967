// STEP One create
export const ADD_DEVICE_TAB_REQUESTED = 'ADD_DEVICE_TAB_REQUESTED';
export const ADD_DEVICE_TAB_SUCCESS = 'ADD_DEVICE_TAB_SUCCESS';
export const ADD_DEVICE_TAB_FAILED = 'ADD_DEVICE_TAB_FAILED';

// STEP Two create
export const ADD_TSAK_TAB_REQUESTED = 'ADD_TSAK_TAB_REQUESTED';
export const ADD_TSAK_TAB_SUCCESS = 'ADD_TSAK_TAB_SUCCESS';
export const ADD_TSAK_TAB_FAILED = 'ADD_TSAK_TAB_FAILED';

// STEP Three create
export const ADD_ASSIGN_TAB_REQUESTED = 'ADD_ASSIGN_TAB_REQUESTED';
export const ADD_ASSIGN_TAB_SUCCESS = 'ADD_ASSIGN_TAB_SUCCESS';
export const ADD_ASSIGN_TAB_FAILED = 'ADD_ASSIGN_TAB_FAILED';

// // DEVICE TAB
// export const STORE_DEVICE_TAB_REQUESTED = 'STORE_DEVICE_TAB_REQUESTED';
// export const STORE_DEVICE_TAB_SUCCESS = 'STORE_DEVICE_TAB_SUCCESS';
// export const STORE_DEVICE_TAB_FAILED = 'STORE_DEVICE_TAB_FAILED';

// // WORKORDER TAB
// export const STORE_WORKORDER_TAB_REQUESTED = 'STORE_WORKORDER_TAB_REQUESTED';
// export const STORE_WORKORDER_TAB_SUCCESS = 'STORE_WORKORDER_TAB_SUCCESS';
// export const STORE_WORKORDER_TAB_FAILED = 'STORE_WORKORDER_TAB_FAILED';

// // ASSIGN TAB
// export const STORE_ASSIGN_TAB_REQUESTED = 'STORE_ASSIGN_TAB_REQUESTED';
// export const STORE_ASSIGN_TAB_SUCCESS = 'STORE_ASSIGN_TAB_SUCCESS';
// export const STORE_ASSIGN_TAB_FAILED = 'STORE_ASSIGN_TAB_FAILED';

// GET ALL ACTIVE WORKORDERS
export const GET_ALL_ACTIVE_WORKORDERS_REQUESTED = 'GET_ALL_ACTIVE_WORKORDERS_REQUESTED';
export const GET_ALL_ACTIVE_WORKORDERS_SUCCESS = 'GET_ALL_ACTIVE_WORKORDERS_SUCCESS';
export const GET_ALL_ACTIVE_WORKORDERS_FAILED = 'GET_ALL_ACTIVE_WORKORDERS_FAILED';

// GET ALL DRAFT WORKORDERS
export const GET_ALL_DRAFT_WORKORDERS_REQUESTED = 'GET_ALL_DRAFT_WORKORDERS_REQUESTED';
export const GET_ALL_DRAFT_WORKORDERS_SUCCESS = 'GET_ALL_DRAFT_WORKORDERS_SUCCESS';
export const GET_ALL_DRAFT_WORKORDERS_FAILED = 'GET_ALL_DRAFT_WORKORDERS_FAILED';

// GET ALL COMPLETED WORKORDERS
export const GET_ALL_COMPLETED_WORKORDERS_REQUESTED = 'GET_ALL_COMPLETED_WORKORDERS_REQUESTED';
export const GET_ALL_COMPLETED_WORKORDERS_SUCCESS = 'GET_ALL_COMPLETED_WORKORDERS_SUCCESS';
export const GET_ALL_COMPLETED_WORKORDERS_FAILED = 'GET_ALL_COMPLETED_WORKORDERS_FAILED';

// // ADD WORKORDER
// export const ADD_WORKORDER_REQUESTED = 'ADD_WORKORDER_REQUESTED';
// export const ADD_WORKORDER_SUCCESS = 'ADD_WORKORDER_SUCCESS';
// export const ADD_WORKORDER_FAILED = 'ADD_WORKORDER_FAILED';

// GET WORKORDER DETAILS
export const WORKORDER_DETAILS_REQUESTED = 'WORKORDER_DETAILS_REQUESTED';
export const WORKORDER_DETAILS_SUCCESS = 'WORKORDER_DETAILS_SUCCESS';
export const WORKORDER_DETAILS_FAILED = 'WORKORDER_DETAILS_FAILED';

// CANCEL WORKORDER REPEAT/RECURRING
export const CANCEL_WORKORDER_REPEAT_REQUESTED = "CANCEL_WORKORDER_REPEAT_REQUESTED";
export const CANCEL_WORKORDER_REPEAT_SUCCESS = "CANCEL_WORKORDER_REPEAT_SUCCESS";
export const CANCEL_WORKORDER_REPEAT_FAILED = "CANCEL_WORKORDER_REPEAT_FAILED";

// UPDATE WORKORDER
export const UPDATE_WORKORDER_REQUESTED = 'UPDATE_WORKORDER_REQUESTED';
export const UPDATE_WORKORDER_SUCCESS = 'UPDATE_WORKORDER_SUCCESS';
export const UPDATE_WORKORDER_FAILED = 'UPDATE_WORKORDER_FAILED';

// DELETE WORKORDER
export const DELETE_WORKORDER_REQUESTED = 'DELETE_WORKORDER_REQUESTED';
export const DELETE_WORKORDER_SUCCESS = 'DELETE_WORKORDER_SUCCESS';
export const DELETE_WORKORDER_FAILED = 'DELETE_WORKORDER_FAILED';

// GET ALL MODELS USING CATEGORY
export const GET_ALL_CATEGORY_MODELS_REQUESTED = 'GET_ALL_CATEGORY_MODELS_REQUESTED';
export const GET_ALL_CATEGORY_MODELS_SUCCESS = 'GET_ALL_CATEGORY_MODELS_SUCCESS';
export const GET_ALL_CATEGORY_MODELS_FAILED = 'GET_ALL_CATEGORY_MODELS_FAILED';

// GET ALL MODELS USING CATEGORY
export const GET_WORKORDER_DETAILS_REQUESTED = 'GET_WORKORDER_DETAILS_REQUESTED';
export const GET_WORKORDER_DETAILS_SUCCESS = 'GET_WORKORDER_DETAILS_SUCCESS';
export const GET_WORKORDER_DETAILS_FAILED = 'GET_WORKORDER_DETAILS_FAILED';

// GET ALL MODELS USING CATEGORY
export const UPDATE_DEVICE_TAB_REQUESTED = 'UPDATE_DEVICE_TAB_REQUESTED';
export const UPDATE_DEVICE_TAB_SUCCESS = 'UPDATE_DEVICE_TAB_SUCCESS';
export const UPDATE_DEVICE_TAB_FAILED = 'UPDATE_DEVICE_TAB_FAILED';

// GET ALL MODELS USING CATEGORY
export const UPDATE_WORKORDER_TAB_TWO_REQUESTED = 'UPDATE_WORKORDER_TAB_TWO_REQUESTED';
export const UPDATE_WORKORDER_TAB_TWO_SUCCESS = 'UPDATE_WORKORDER_TAB_TWO_SUCCESS';
export const UPDATE_WORKORDER_TAB_TWO_FAILED = 'UPDATE_WORKORDER_TAB_TWO_FAILED';

// GET ALL MODELS USING CATEGORY
export const UPDATE_WORKORDER_TAB_THREE_REQUESTED = 'UPDATE_WORKORDER_TAB_THREE_REQUESTED';
export const UPDATE_WORKORDER_TAB_THREE_SUCCESS = 'UPDATE_WORKORDER_TAB_THREE_SUCCESS';
export const UPDATE_WORKORDER_TAB_THREE_FAILED = 'UPDATE_WORKORDER_TAB_THREE_FAILED';

// GET ALL MODELS USING CATEGORY
export const UPLOAD_WO_REQUESTED = 'UPLOAD_WO_REQUESTED';
export const UPLOAD_WO_SUCCESS = 'UPLOAD_WO_SUCCESS';
export const UPLOAD_WO_FAILED = 'UPLOAD_WO_FAILED';

// GET ALL MODELS USING CATEGORY
export const CHANGE_STEP_IN_CREATION = 'CHANGE_STEP_IN_CREATION';

// GET ALL ACTIVE WORKORDER USERS
export const GET_ALL_ACTIVE_WORKORDER_USERS_REQUESTED = "GET_ALL_ACTIVE_WORKORDER_USERS_REQUESTED";
export const GET_ALL_ACTIVE_WORKORDER_USERS_SUCCESS = "GET_ALL_ACTIVE_WORKORDER_USERS_SUCCESS";
export const GET_ALL_ACTIVE_WORKORDER_USERS_FAILED = "GET_ALL_ACTIVE_WORKORDER_USERS_FAILED";

// CHANGE ACTIVE USERS STATUS-
export const CHANGE_ACTIVE_WORKORDER_USER_STATUS_REQUESTED = "CHANGE_ACTIVE_WORKORDER_USER_STATUS_REQUESTED";
export const CHANGE_ACTIVE_WORKORDER_USER_STATUS_SUCCESS = "CHANGE_ACTIVE_WORKORDER_USER_STATUS_SUCCESS";
export const CHANGE_ACTIVE_WORKORDER_USER_STATUS_FAILED = "CHANGE_ACTIVE_WORKORDER_USER_STATUS_FAILED";




// Get Active Workorders For a Device ===== In Device Detail Page
export const GET_ALL_DEVICE_WORKORDERS_REQUESTED = "GET_ALL_DEVICE_WORKORDERS_REQUESTED";
export const GET_ALL_DEVICE_WORKORDERS_SUCCESS = "GET_ALL_DEVICE_WORKORDERS_SUCCESS";
export const GET_ALL_DEVICE_WORKORDERS_FAILED = "GET_ALL_DEVICE_WORKORDERS_FAILED";

// Get Workorder History Log ===== In Device Detail Page
export const GET_WO_HISTORY_LOG_REQUESTED = "GET_WO_HISTORY_LOG_REQUESTED";
export const GET_WO_HISTORY_LOG_SUCCESS = "GET_WO_HISTORY_LOG_SUCCESS";
export const GET_WO_HISTORY_LOG_FAILED = "GET_WO_HISTORY_LOG_FAILED";

// SET UPLOAD WORKORDER MODAL-
export const SET_WORKODR_UPLOAD_MODAL = "SET_WORKODR_UPLOAD_MODAL";

// RESET WO ERROR-
export const RESET_WO_ERRORS = "RESET_WO_ERRORS";

//RESET WO DETAILS
export const RESET_WO_DETAILS = "RESET_WO_DETAILS";

// GET ALL SUBMITTED WO PDF
export const GET_ALL_SUBMITTED_WO_PDF_REQUESTED = "GET_ALL_SUBMITTED_WO_PDF_REQUESTED";
export const GET_ALL_SUBMITTED_WO_PDF_SUCCESS = "GET_ALL_SUBMITTED_WO_PDF_SUCCESS";
export const GET_ALL_SUBMITTED_WO_PDF_FAILED = "GET_ALL_SUBMITTED_WO_PDF_FAILED";

// PUBLISH THE DRAFT
export const PUBLISH_THE_DRAFT_PROJECT_REQUESTED = "PUBLISH_THE_DRAFT_PROJECT_REQUESTED";
export const PUBLISH_THE_DRAFT_PROJECT_SUCCESS = "PUBLISH_THE_DRAFT_PROJECT_SUCCESS";
export const PUBLISH_THE_DRAFT_PROJECT_FAILED = "PUBLISH_THE_DRAFT_PROJECT_FAILED";

// PUBLISH THE DRAFT
export const SET_CONFIRM_EDIT_POPUP = "SET_CONFIRM_EDIT_POPUP";

// CHANGE SEARCH
export const CHANGE_WORKORDERS_SEARCH_REQUESTED = "CHANGE_WORKORDERS_SEARCH_REQUESTED";

// CHANGE SEARCH IN ACTIVE WO USERS
export const CHANGE_ACTIVE_WO_USERS_SEARCH_REQUESTED = "CHANGE_ACTIVE_WO_USERS_SEARCH_REQUESTED";