import { call, put, debounce } from 'redux-saga/effects';
import instance from "../../../api/api_instance";

async function getApi(data) {
  const search = data.search.replace(/\s+/g, ' ').trim();
  try {
    const result = instance({
      url: `/v1/model?search=${encodeURIComponent(search)}&limit=${data.limit}&page=${data.page + 1}&sort_column=${data.sorting}&sort_order=${data.sort == 1 ? "asc" : data.sort == 2 ? "desc" : ""}`,
      method: "GET",
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}

function* fetchAllModels(action) {
  try {
    const res = yield call(getApi, action.payload);
    yield put({ type: "GET_ALL_MODELS_SUCCESS", allModels: res.data });
  } catch (e) {
    yield put({ type: "GET_ALL_MODELS_FAILED", message: e.message });
  }
}

function* allModelsSaga() {
  yield debounce(1000, "GET_ALL_MODELS_REQUESTED", fetchAllModels);
}

export default allModelsSaga;