import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../api/api_instance";

async function getApi(data) {

    try {
        const result = instance({
            url: `/v1/roles/${data.id}`,
            method: "PUT",
            data: data.data,
        }).then((response) => {
            return response;
        })
        return await result;
    } catch (error) {
        throw error;
    }
}

function* UserRole(action) {
    try {
        const res = yield call(getApi, action.payload);
        const toastrData = {
            content: `${res.data.title} User Role details updated Successfully`,
            type: "success"
        }
        yield put({ type: "UPDATE_USER_ROLES_SUCCESS", deviceData: res.data });
        yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
        window.location.href = "/user-roles"
    } catch (e) {
        const toastrData = {
            content: "Failed to Update User Role details",
            type: "failed"
        }
        if (e.response.status === 406 || e.response.status === 404 || e.response.status === 422) {
            yield put({ type: "UPDATE_USER_ROLES_FAILED", message: e.response.data.errors });
            yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
        }else {
            yield put({ type: "UPDATE_USER_ROLES_FAILED", message: "Some error occured" });
            yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
        }

        if (e.response.status === 409) {
            const toastrConflictData = {
                content: "Failed to update User Role Details",
                type: "failed"
            }
            yield put({ type: "UPDATE_USER_ROLES_FAILED", message: e.response.data.errors });
            yield put({ type: "SET_TOASTER_SUCCESS", data: toastrConflictData });
        }

    }
}

function* updateUserRolesSaga() {
    yield takeEvery("UPDATE_USER_ROLES_REQUESTED", UserRole);
}

export default updateUserRolesSaga;