import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../api/api_instance";

async function getApi(data) {
    try {
        const result = instance({
            url: `v1/asset_notes/${data.id}`,
            method: "DELETE",
        }).then((response) => {
            return response;
        })
        return await result;
    } catch (error) {
        throw error;
    }
}

function* deleteAssetNote(action) {
    const data = {
        search: '',
        page: 0,
        limit: 10,
        sort: "",
        sorting: "",
        id:action.payload.id,
        asset_notiable_type: action.payload.assetNotiableType,
        asset_notiable_id: action.payload.assetNotiableTypeId,
    }

    try {
        const res = yield call(getApi, action.payload);
        const toastrData = {
            content: `${res.data.note.title} Note deleted Successfully`,
            type: "success"
        }
        yield put({ type: 'DELETE_ASSET_NOTE_SUCCESS', allAssetNotesList: res.data });
        yield put({ type: 'GET_ALL_ASSET_NOTES_REQUESTED', payload: data });
        yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });

    } catch (e) {
        const toastrData = {
            content: "Failed to delete this Note",
            type: "failed"
        }
        if (e.response.status === 406 || e.response.status === 404) {
            yield put({ type: "DELETE_ASSET_NOTE_FAILED", message: e.response.data });
            yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
        } else {
            yield put({ type: "DELETE_ASSET_NOTE_FAILED", message: "Some error occurred" });
            yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
        }

        if (e.response.status === 500) {
            const toastrFailedData = {
                content: e.response.data.errors ? e.response.data.errors : "Something went wrong!",
                type: "failed"
            };
            yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
        }
    }
}

function* deleteAssetNoteSaga() {
    yield takeEvery('DELETE_ASSET_NOTE_REQUESTED', deleteAssetNote);
}

export default deleteAssetNoteSaga;