// GET ALL ANAGLYPH
export const GET_ALL_ANAGLYPH_REQUESTED = 'GET_ALL_ANAGLYPH_REQUESTED';
export const GET_ALL_ANAGLYPH_SUCCESS = 'GET_ALL_ANAGLYPH_SUCCESS';
export const GET_ALL_ANAGLYPH_FAILED = 'GET_ALL_ANAGLYPH_FAILED';


// ADD AN ANAGLYPH
export const ADD_ANAGLYPH_REQUESTED = 'ADD_ANAGLYPH_REQUESTED';
export const ADD_ANAGLYPH_SUCCESS = 'ADD_ANAGLYPH_SUCCESS';
export const ADD_ANAGLYPH_FAILED = 'ADD_ANAGLYPH_FAILED';


// GET AN ANAGLYPH
export const ANAGLYPH_DETAILS_REQUESTED = 'ANAGLYPH_DETAILS_REQUESTED';
export const ANAGLYPH_DETAILS_SUCCESS = 'ANAGLYPH_DETAILS_SUCCESS';
export const ANAGLYPH_DETAILS_FAILED = 'ANAGLYPH_DETAILS_FAILED';


// UPDATE AN ANAGLYPH
export const UPDATE_ANAGLYPH_REQUESTED = 'UPDATE_ANAGLYPH_REQUESTED';
export const UPDATE_ANAGLYPH_SUCCESS = 'UPDATE_ANAGLYPH_SUCCESS';
export const UPDATE_ANAGLYPH_FAILED = 'UPDATE_ANAGLYPH_FAILED';

// UPDATE PURCHASE URL
export const UPDATE_PURCHSE_URL_REQUESTED = 'UPDATE_PURCHSE_URL_REQUESTED';
export const UPDATE_PURCHSE_URL_SUCCESS = 'UPDATE_PURCHSE_URL_SUCCESS';
export const UPDATE_PURCHSE_URL_FAILED = 'UPDATE_PURCHSE_URL_FAILED';


// DELETE AN ANAGLYPH
export const DELETE_ANAGLYPH_REQUESTED = 'DELETE_ANAGLYPH_REQUESTED';
export const DELETE_ANAGLYPH_SUCCESS = 'DELETE_ANAGLYPH_SUCCESS';
export const DELETE_ANAGLYPH_FAILED = 'DELETE_ANAGLYPH_FAILED';

// UNLINK  ANAGLYPH
export const UNLINK_ANAGLYPH_REQUESTED = 'UNLINK_ANAGLYPH_REQUESTED';
export const UNLINK_ANAGLYPH_SUCCESS = 'UNLINK_ANAGLYPH_SUCCESS';
export const UNLINK_ANAGLYPH_FAILED = 'UNLINK_ANAGLYPH_FAILED';




// ========= PARTS ===============================================

// GET ALL PARTS
export const GET_ALL_PARTS_REQUESTED = 'GET_ALL_PARTS_REQUESTED';
export const GET_ALL_PARTS_SUCCESS = 'GET_ALL_PARTS_SUCCESS';
export const GET_ALL_PARTS_FAILED = 'GET_ALL_PARTS_FAILED';

// ADD A PART
export const ADD_PART_REQUESTED = 'ADD_PART_REQUESTED';
export const ADD_PART_SUCCESS = 'ADD_PART_SUCCESS';
export const ADD_PART_FAILED = 'ADD_PART_FAILED';

// GET PART DETAILS
export const PART_DETAILS_REQUESTED = 'PART_DETAILS_REQUESTED';
export const PART_DETAILS_SUCCESS = 'PART_DETAILS_SUCCESS';
export const PART_DETAILS_FAILED = 'PART_DETAILS_FAILED';

// UPDATE A PART
export const UPDATE_PART_REQUESTED = 'UPDATE_PART_REQUESTED';
export const UPDATE_PART_SUCCESS = 'UPDATE_PART_SUCCESS';
export const UPDATE_PART_FAILED = 'UPDATE_PART_FAILED';

// DELETE A PART
export const DELETE_PART_REQUESTED = 'DELETE_PART_REQUESTED';
export const DELETE_PART_SUCCESS = 'DELETE_PART_SUCCESS';
export const DELETE_PART_FAILED = 'DELETE_PART_FAILED';

// DELETE ALL PARTS
export const DELETE_ALL_PARTS_REQUESTED = "DELETE_ALL_PARTS_REQUESTED";
export const DELETE_ALL_PARTS_SUCCESS = "DELETE_ALL_PARTS_SUCCESS";
export const DELETE_ALL_PARTS_FAILED = "DELETE_ALL_PARTS_FAILED";

// CHANGE SEARCH
export const CHANGE_PARTS_SEARCH_REQUESTED = "CHANGE_PARTS_SEARCH_REQUESTED";

// ================= PART NOTES ========================================
// GET ALL NOTES OF A PART
export const GET_ALL_PART_NOTES_REQUESTED = "GET_ALL_PART_NOTES_REQUESTED";
export const GET_ALL_PART_NOTES_SUCCESS = "GET_ALL_PART_NOTES_SUCCESS";
export const GET_ALL_PART_NOTES_FAILED = "GET_ALL_PART_NOTES_FAILED";

// CREATE A NOTE FOR A PART
export const ADD_PART_NOTE_REQUESTED = "ADD_PART_NOTE_REQUESTED";
export const ADD_PART_NOTE_SUCCESS = "ADD_PART_NOTE_SUCCESS";
export const ADD_PART_NOTE_FAILED = "ADD_PART_NOTE_FAILED";

// GET A NOTE OF A PART
export const GET_PART_NOTE_DETAILS_REQUESTED = "GET_PART_NOTE_DETAILS_REQUESTED";
export const GET_PART_NOTE_DETAILS_SUCCESS = "GET_PART_NOTE_DETAILS_SUCCESS";
export const GET_PART_NOTE_DETAILS_FAILED = "GET_PART_NOTE_DETAILS_FAILED";

// UPDATE A NOTE OF A PART
export const UPDATE_PART_NOTE_REQUESTED = "UPDATE_PART_NOTE_REQUESTED";
export const UPDATE_PART_NOTE_SUCCESS = "UPDATE_PART_NOTE_SUCCESS";
export const UPDATE_PART_NOTE_FAILED = "UPDATE_PART_NOTE_FAILED";

// DELETE A NOTE OF A PART
export const DELETE_PART_NOTE_REQUESTED = "DELETE_PART_NOTE_REQUESTED";
export const DELETE_PART_NOTE_SUCCESS = "DELETE_PART_NOTE_SUCCESS";
export const DELETE_PART_NOTE_FAILED = "DELETE_PART_NOTE_FAILED";

// CHANGE SEARCH
export const CHANGE_PART_NOTES_SEARCH_REQUESTED = "CHANGE_PART_NOTES_SEARCH_REQUESTED";


// ============ CSV UPLOAD =============================================

// CSV PART UPLOAD
export const CSV_PART_UPLOAD_REQUESTED = 'CSV_PART_UPLOAD_REQUESTED';
export const CSV_PART_UPLOAD_SUCCESS = 'CSV_PART_UPLOAD_SUCCESS';
export const CSV_PART_UPLOAD_FAILED = 'CSV_PART_UPLOAD_FAILED';

// GET UPLOADED CSV PARTS
export const GET_UPLOADING_CSV_PARTS_REQUESTED = 'GET_UPLOADING_CSV_PARTS_REQUESTED';
export const GET_UPLOADING_CSV_PARTS_SUCCESS = 'GET_UPLOADING_CSV_PARTS_SUCCESS';
export const GET_UPLOADING_CSV_PARTS_FAILED = 'GET_UPLOADING_CSV_PARTS_FAILED';

// close the error messages
export const RESET_ERRORS_REQUESTED = 'RESET_ERRORS_REQUESTED';

// SET ADD ANAGLYPH MODAL
export const SET_SHOW_ANAGLYPH_MODAL = "SET_SHOW_ANAGLYPH_MODAL";

// csv upload url modal
export const SET_CSV_UPLOAD_URL_MODAL = 'SET_CSV_UPLOAD_URL_MODAL';

// csv upload MEDIA modal
export const SET_CSV_UPLOAD_MEDIA_MODAL = 'SET_CSV_UPLOAD_MEDIA_MODAL';

// CHANGE SEARCH
export const CHANGE_ANAGLYPH_SEARCH_REQUESTED = "CHANGE_ANAGLYPH_SEARCH_REQUESTED";

// ============ 3D THUMBNAIL =============================================

// Update thumbnail
export const UPDATE_THUMBNAIL_REQUESTED = 'UPDATE_THUMBNAIL_REQUESTED';
export const UPDATE_THUMBNAIL_SUCCESS = 'UPDATE_THUMBNAIL_SUCCESS';
export const UPDATE_THUMBNAIL_FAILED = 'UPDATE_THUMBNAIL_FAILED';

// Update thumbnail
export const SET_THUMBNAIL_UPDATE_MODAL_REQUESTED = 'SET_THUMBNAIL_UPDATE_MODAL_REQUESTED'

// delete thumbnail
export const DELETE_THUMBNAIL_REQUESTED = 'DELETE_THUMBNAIL_REQUESTED';
export const DELETE_THUMBNAIL_SUCCESS = 'DELETE_THUMBNAIL_SUCCESS';
export const DELETE_THUMBNAIL_FAILED = 'DELETE_THUMBNAIL_FAILED';