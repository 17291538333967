// GET THE PROFILE DETAILS
export const GET_PROFILE_DETAILS_REQUESTED = "GET_PROFILE_DETAILS_REQUESTED";
export const GET_PROFILE_DETAILS_SUCCESS = "GET_PROFILE_DETAILS_SUCCESS";
export const GET_PROFILE_DETAILS_FAILED = "GET_PROFILE_DETAILS_FAILED";

// UPDATE THE PROFILE
export const UPDATE_PROFILE_REQUESTED = "UPDATE_PROFILE_REQUESTED";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";

// UPDATE THE AVAILABILITY STATUS
export const AVAILABILITY_STATUS_UPDATE_REQUESTED = "AVAILABILITY_STATUS_UPDATE_REQUESTED";
export const AVAILABILITY_STATUS_UPDATE_SUCCESS = "AVAILABILITY_STATUS_UPDATE_SUCCESS";
export const AVAILABILITY_STATUS_UPDATE_FAILED = "AVAILABILITY_STATUS_UPDATE_FAILED";

// RESET THE FORM ON CLOSE
export const SET_PASSWORD_MODAL_REQUESTED = "SET_PASSWORD_MODAL_REQUESTED";

// RESET error 
export const RESET_PROFILE_UPDATE_ERROR = "RESET_PROFILE_UPDATE_ERROR";