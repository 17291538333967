// GET ALL ERROR CODES (error_type: error_codes = 1) ====================================
export const GET_ALL_ERROR_CODES_REQUESTED = 'GET_ALL_ERROR_CODES_REQUESTED';
export const GET_ALL_ERROR_CODES_SUCCESS = 'GET_ALL_ERROR_CODES_SUCCESS';
export const GET_ALL_ERROR_CODES_FAILED = 'GET_ALL_ERROR_CODES_FAILED';

// ADD AN ERROR AND ALARM CODE (error_type: error_codes = 1)
export const ADD_ERROR_CODE_REQUESTED = 'ADD_ERROR_CODE_REQUESTED';
export const ADD_ERROR_CODE_SUCCESS = 'ADD_ERROR_CODE_SUCCESS';
export const ADD_ERROR_CODE_FAILED = 'ADD_ERROR_CODE_FAILED';

// GET ERROR AND ALARM CODE DETAILS (error_type: error_codes = 1)
export const ERROR_CODE_DETAILS_REQUESTED = 'ERROR_CODE_DETAILS_REQUESTED';
export const ERROR_CODE_DETAILS_SUCCESS = 'ERROR_CODE_DETAILS_SUCCESS';
export const ERROR_CODE_DETAILS_FAILED = 'ERROR_CODE_DETAILS_FAILED';

// EDIT/UPDATE AN ERROR AND ALARM  CODE (error_type: error_codes = 1)
export const UPDATE_ERROR_CODE_REQUESTED = 'UPDATE_ERROR_CODE_REQUESTED';
export const UPDATE_ERROR_CODE_SUCCESS = 'UPDATE_ERROR_CODE_SUCCESS';
export const UPDATE_ERROR_CODE_FAILED = 'UPDATE_ERROR_CODE_FAILED';

// DELETE AN ERROR AND ALARM  CODE (error_type: error_codes = 1)
export const DELETE_ERROR_CODE_REQUESTED = 'DELETE_ERROR_CODE_REQUESTED';
export const DELETE_ERROR_CODE_SUCCESS = 'DELETE_ERROR_CODE_SUCCESS';
export const DELETE_ERROR_CODE_FAILED = 'DELETE_ERROR_CODE_FAILED';




// GET ALL PROCEDURES FOR THE ERROR CODES (error_type: error_code = 1) ========================================
export const GET_ALL_EC_PROCEDURES_REQUESTED = 'GET_ALL_EC_PROCEDURES_REQUESTED';
export const GET_ALL_EC_PROCEDURES_SUCCESS = 'GET_ALL_EC_PROCEDURES_SUCCESS';
export const GET_ALL_EC_PROCEDURES_FAILED = 'GET_ALL_EC_PROCEDURES_FAILED';

// GET ALL MCODES (error_type: mcodes = 2) =========================================
export const GET_ALL_MCODES_REQUESTED = 'GET_ALL_MCODES_REQUESTED';
export const GET_ALL_MCODES_SUCCESS = 'GET_ALL_MCODES_SUCCESS';
export const GET_ALL_MCODES_FAILED = 'GET_ALL_MCODES_FAILED';

// GET ALL ALARM CODES (error_type: alarm_codes = 3) =========================================
export const GET_ALL_ALARM_CODES_REQUESTED = 'GET_ALL_ALARM_CODES_REQUESTED';
export const GET_ALL_ALARM_CODES_SUCCESS = 'GET_ALL_ALARM_CODES_SUCCESS';
export const GET_ALL_ALARM_CODES_FAILED = 'GET_ALL_ALARM_CODES_FAILED';

// GET ALL ERROR CODE LINKING LIST (error_type: alarm_codes = 3) =========================================
export const GET_ALL_ERROR_CODE_LISTING_LINK_REQUESTED = 'GET_ALL_ERROR_CODE_LISTING_LINK_REQUESTED';
export const GET_ALL_ERROR_CODE_LISTING_LINK_SUCCESS = 'GET_ALL_ERROR_CODE_LISTING_LINK_SUCCESS';
export const GET_ALL_ERROR_CODE_LISTING_LINK_FAILED = 'GET_ALL_ERROR_CODE_LISTING_LINK_FAILED';


// GET ALL ERROR CODE LINKING PROCEDURE LIST  =========================================
export const GET_ALL_PROCEDURE_LINKED_TO_ERROR_REQUESTED = 'GET_ALL_PROCEDURE_LINKED_TO_ERROR_REQUESTED';
export const GET_ALL_PROCEDURE_LINKED_TO_ERROR_SUCCESS = 'GET_ALL_PROCEDURE_LINKED_TO_ERROR_SUCCESS';
export const GET_ALL_PROCEDURE_LINKED_TO_ERROR_FAILED = 'GET_ALL_PROCEDURE_LINKED_TO_ERROR_FAILED';

// GET ALL ERROR CODE LINKING TROUBLESHOOT LIST =========================================
export const GET_ALL_TROUBLESHOOT_LINKED_TO_ERROR_REQUESTED = 'GET_ALL_TROUBLESHOOT_LINKED_TO_ERROR_REQUESTED';
export const GET_ALL_TROUBLESHOOT_LINKED_TO_ERROR_SUCCESS = 'GET_ALL_TROUBLESHOOT_LINKED_TO_ERROR_SUCCESS';
export const GET_ALL_TROUBLESHOOT_LINKED_TO_ERROR_FAILED = 'GET_ALL_TROUBLESHOOT_LINKED_TO_ERROR_FAILED';


// RESET ERROR MESSAGES
export const RESET_ERROR_CODE_ERRORS_REQUESTED = "RESET_ERROR_CODE_ERRORS_REQUESTED";

// ERROR CODE MODAL
export const SET_ERROR_CODE_MODAL = "SET_ERROR_CODE_MODAL";

// CHANGE SEARCH
export const CHANGE_ERROR_CODES_SEARCH_REQUESTED = "CHANGE_ERROR_CODES_SEARCH_REQUESTED";
export const CHANGE_M_CODES_SEARCH_REQUESTED = "CHANGE_M_CODES_SEARCH_REQUESTED";
export const CHANGE_ALARM_CODES_SEARCH_REQUESTED = "CHANGE_ALARM_CODES_SEARCH_REQUESTED";