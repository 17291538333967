import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../api/api_instance";

async function getApi(data) {

  const postData = {
      part_id: data.part_id,
      model_id: data.model_id,
      description: data.description,
    }

    try {
        const result = instance({
            url: `v1/model/${data.model_id}/anaglyph/parts/${data.part_id}/notes`,
            method: "POST",
            data: postData
        }).then((response) => {
            return response;
        })
        return await result;
    } catch (error) {
        throw error;
    }
}

function* fetchPartNotes(action) {
    const data = {
        model_id: action.payload.model_id,
        id: action.payload.part_id,
        search: '',
        page: 0,
        limit: 10,
    }

    try {
      const res = yield call(getApi, action.payload);
        const toastrData = {
            content: `Note description added Successfully`,
            type: "success"
        };

        yield put({ type: 'ADD_PART_NOTE_SUCCESS', allPartNotesList: res.data });
        yield put({ type: 'GET_ALL_PART_NOTES_REQUESTED', payload: data });
        yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
    } catch (e) {
        if (e.response.status === 406 || e.response.status === 404 || e.response.status === 422) {
            yield put({ type: "ADD_PART_NOTE_FAILED", message: e.response.data });
        } else {
            yield put({ type: "ADD_PART_NOTE_FAILED", message: "Some error occurred" });
        }
        if (e.response.status === 500) {
            const toastrFailedData = {
                content: e.response.data.errors ? e.response.data.errors : "Something went wrong!",
                type: "failed"
            };
            yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
        }
    }
}

function* addPartNoteSaga() {
    yield takeEvery('ADD_PART_NOTE_REQUESTED', fetchPartNotes);
}

export default addPartNoteSaga;