// GET ALL NOTES
export const GET_ALL_PERSONAL_NOTES_REQUESTED = "GET_ALL_PERSONAL_NOTES_REQUESTED";
export const GET_ALL_PERSONAL_NOTES_SUCCESS = "GET_ALL_PERSONAL_NOTES_SUCCESS";
export const GET_ALL_PERSONAL_NOTES_FAILED = "GET_ALL_PERSONAL_NOTES_FAILED";

// CREATE A NOTE
export const CREATE_PERSONAL_NOTE_REQUESTED = "CREATE_PERSONAL_NOTE_REQUESTED";
export const CREATE_PERSONAL_NOTE_SUCCESS = "CREATE_PERSONAL_NOTE_SUCCESS";
export const CREATE_PERSONAL_NOTE_FAILED = "CREATE_PERSONAL_NOTE_FAILED";

// GET A NOTE DETAILS
export const GET_PERSONAL_NOTE_DETAILS_REQUESTED = "GET_PERSONAL_NOTE_DETAILS_REQUESTED";
export const GET_PERSONAL_NOTE_DETAILS_SUCCESS = "GET_PERSONAL_NOTE_DETAILS_SUCCESS";
export const GET_PERSONAL_NOTE_DETAILS_FAILED = "GET_PERSONAL_NOTE_DETAILS_FAILED";

// UPDATE A NOTE
export const UPDATE_PERSONAL_NOTE_REQUESTED = "UPDATE_PERSONAL_NOTE_REQUESTED";
export const UPDATE_PERSONAL_NOTE_SUCCESS = "UPDATE_PERSONAL_NOTE_SUCCESS";
export const UPDATE_PERSONAL_NOTE_FAILED = "UPDATE_PERSONAL_NOTE_FAILED";

// DELETE A NOTE
export const DELETE_PERSONAL_NOTE_REQUESTED = "DELETE_PERSONAL_NOTE_REQUESTED";
export const DELETE_PEROSNAL_NOTE_SUCCESS = "DELETE_PEROSNAL_NOTE_SUCCESS";
export const DELETE_PERSONAL_NOTE_FAILED = "DELETE_PERSONAL_NOTE_FAILED";

// NOTE MODAL
export const SET_PERSONAL_NOTES_MODAL_REQUESTED = "SET_PERSONAL_NOTES_MODAL_REQUESTED";

// RESET THE ERRORS - MESSAGES
export const RESET_PERSONAL_NOTES_ERROR_REQUESTED = "RESET_PERSONAL_NOTES_ERROR_REQUESTED";

// CHANGE SEARCH
export const CHANGE_PERSONAL_NOTES_SEARCH_REQUESTED = "CHANGE_PERSONAL_NOTES_SEARCH_REQUESTED";