// Get All Users
export const GET_ALL_USERS_REQUESTED = 'GET_ALL_USERS_REQUESTED';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILED = 'GET_ALL_USERS_FAILED';

// POST - ADD/CREATE USER
export const ADD_USER_REQUESTED = 'ADD_USER_REQUESTED';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';

// POST - EDIT/UPDATE USER
export const EDIT_USER_REQUESTED = "EDIT_USER_REQUESTED";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILED = "EDIT_USER_FAILED";

// GET - GET A USER DETAILS
export const USER_DETAILS_REQUESTED = 'USER_DETAILS_REQUESTED';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAILED = 'USER_DETAILS_FAILED';

// POST - MANAGE PASSWORD
export const MANAGE_PASSWORD_REQUESTED = "MANAGE_PASSWORD_REQUESTED";
export const MANAGE_PASSWORD_SUCCESS = "MANAGE_PASSWORD_SUCCESS";
export const MANAGE_PASSWORD_FAILED = "MANAGE_PASSWORD_FAILED";

// POST - EDIT/UPDATE USER ROLE
export const EDIT_USER_ROLE_REQUESTED = "EDIT_USER_ROLE_REQUESTED";
export const EDIT_USER_ROLE_SUCCESS = "EDIT_USER_ROLE_SUCCESS";
export const EDIT_USER_ROLE_FAILED = "EDIT_USER_ROLE_FAILED";


// ACTIVE USERS LIST
export const ACTIVE_USERS_REQUESTED = 'ACTIVE_USERS_REQUESTED';
export const ACTIVE_USERS_SUCCESS = 'ACTIVE_USERS_SUCCESS';
export const ACTIVE_USERS_FAILED = 'ACTIVE_USERS_FAILED';

// INACTIVE USERS LIST
export const INACTIVE_USERS_REQUESTED = 'INACTIVE_USERS_REQUESTED';
export const INACTIVE_USERS_SUCCESS = 'INACTIVE_USERS_SUCCESS';
export const INACTIVE_USERS_FAILED = 'INACTIVE_USERS_FAILED';

// UPDATE THE AVAILABILITY STATUS
export const USER_AVAILABILITY_STATUS_UPDATE_REQUESTED = "USER_AVAILABILITY_STATUS_UPDATE_REQUESTED";
export const USER_AVAILABILITY_STATUS_UPDATE_SUCCESS = "USER_AVAILABILITY_STATUS_UPDATE_SUCCESS";
export const USER_AVAILABILITY_STATUS_UPDATE_FAILED = "USER_AVAILABILITY_STATUS_UPDATE_FAILED";

// SET PASSWORD MODAL
export const SET_PASSWORD_MODAL_REQUESTED = "SET_PASSWORD_MODAL_REQUESTED";

// RESET USER ERROR MESSAGE
export const USER_RESET_ERRORS = 'USER_RESET_ERRORS';

// GET ALL USERS BY A ROLE
export const GET_ALL_USERS_BY_ROLE_REQUESTED = "GET_ALL_USERS_BY_ROLE_REQUESTED";
export const GET_ALL_USERS_BY_ROLE_SUCCESS = "GET_ALL_USERS_BY_ROLE_SUCCESS";
export const GET_ALL_USERS_BY_ROLE_FAILED = "GET_ALL_USERS_BY_ROLE_FAILED";

// GET ALL USERS WITH CURRENT
export const GET_ALL_USERS_WITH_CURRENT_REQUESTED = "GET_ALL_USERS_WITH_CURRENT_REQUESTED";
export const GET_ALL_USERS_WITH_CURRENT_SUCCESS = "GET_ALL_USERS_WITH_CURRENT_SUCCESS";
export const GET_ALL_USERS_WITH_CURRENT_FAILED = "GET_ALL_USERS_WITH_CURRENT_FAILED";

// CHANGE SEARCH FOR ALL USERS
export const CHANGE_USERS_SEARCH_REQUESTED = "CHANGE_USERS_SEARCH_REQUESTED";

