// GET ALL USER GROUPS
export const GET_ALL_USER_GROUPS_REQUESTED = 'GET_ALL_USER_GROUPS_REQUESTED';
export const GET_ALL_USER_GROUPS_SUCCESS = 'GET_ALL_USER_GROUPS_SUCCESS';
export const GET_ALL_USER_GROUPS_FAILED = 'GET_ALL_USER_GROUPS_FAILED';


// ACTIVE USER GROUPS
export const ACTIVE_USER_GROUPS_REQUESTED = 'ACTIVE_USER_GROUPS_REQUESTED';
export const ACTIVE_USER_GROUPS_SUCCESS = 'ACTIVE_USER_GROUPS_SUCCESS';
export const ACTIVE_USER_GROUPS_FAILED = 'ACTIVE_USER_GROUPS_FAILED';


// INACTIVE USER GROUPS
export const INACTIVE_USER_GROUPS_REQUESTED = 'INACTIVE_USER_GROUPS_REQUESTED';
export const INACTIVE_USER_GROUPS_SUCCESS = 'INACTIVE_USER_GROUPS_SUCCESS';
export const INACTIVE_USER_GROUPS_FAILED = 'INACTIVE_USER_GROUPS_FAILED';


// ADD/CREATE AN USER GROUP
export const ADD_USER_GROUP_REQUESTED = 'ADD_USER_GROUP_REQUESTED';
export const ADD_USER_GROUP_SUCCESS = 'ADD_USER_GROUP_SUCCESS';
export const ADD_USER_GROUP_FAILED = 'ADD_USER_GROUP_FAILED';


// GET AN USER GROUP DETAILS
export const USER_GROUP_DETAILS_REQUESTED = 'USER_GROUP_DETAILS_REQUESTED';
export const USER_GROUP_DETAILS_SUCCESS = 'USER_GROUP_DETAILS_SUCCESS';
export const USER_GROUP_DETAILS_FAILED = 'USER_GROUP_DETAILS_FAILED';


// UPDATE AN USER GROUP
export const UPDATE_USER_GROUP_REQUESTED = 'UPDATE_USER_GROUP_REQUESTED';
export const UPDATE_USER_GROUP_SUCCESS = 'UPDATE_USER_GROUP_SUCCESS';
export const UPDATE_USER_GROUP_FAILED = 'UPDATE_USER_GROUP_FAILED';


// DELETE AN USER GROUP
export const DELETE_USER_GROUP_REQUESTED = 'DELETE_USER_GROUP_REQUESTED';
export const DELETE_USER_GROUP_SUCCESS = 'DELETE_USER_GROUP_SUCCESS';
export const DELETE_USER_GROUP_FAILED = 'DELETE_USER_GROUP_FAILED';

// GET ALL USERS SELECTED
export const GET_ALL_USERS_GROUP_REQUESTED = 'GET_ALL_USERS_GROUP_REQUESTED';
export const GET_ALL_USERS_GROUP_SUCCESS = 'GET_ALL_USERS_GROUP_SUCCESS';
export const GET_ALL_USERS_GROUP_FAILED = 'GET_ALL_USERS_GROUP_FAILED';


// RESET ERROR MESSAGES
export const RESET_USER_GROUP_REQUESTED = "RESET_USER_GROUP_REQUESTED";

// CHANGE SEARCH IN USER GROUPS
export const CHANGE_USER_GROUPS_SEARCH_REQUESTED = "CHANGE_USER_GROUPS_SEARCH_REQUESTED";