// GET ALL MEDIAS
export const ALL_MEDIAS_REQUESTED = 'ALL_MEDIAS_REQUESTED';
export const ALL_MEDIAS_SUCCESS = 'ALL_MEDIAS_SUCCESS';
export const ALL_MEDIAS_FAILED = 'ALL_MEDIAS_FAILED';


// UPDATE MEDIA TITLE
export const UPDATE_MEDIA_REQUESTED = 'UPDATE_MEDIA_REQUESTED';
export const UPDATE_MEDIA_SUCCESS = 'UPDATE_MEDIA_SUCCESS';
export const UPDATE_MEDIA_FAILED = 'UPDATE_MEDIA_FAILED';


// DELETE MEDIA
export const DELETE_MEDIA_REQUESTED = 'DELETE_MEDIA_REQUESTED';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_FAILED = 'DELETE_MEDIA_FAILED';

// SEARCH CHANGE
export const CHANGE_MEDIA_SEARCH_REQUESTED = "CHANGE_MEDIA_SEARCH_REQUESTED";