// GET ALL SAFETY MEASURES
export const GET_ALL_SAFETY_MEASURES_REQUESTED = 'GET_ALL_SAFETY_MEASURES_REQUESTED';
export const GET_ALL_SAFETY_MEASURES_SUCCESS = 'GET_ALL_SAFETY_MEASURES_SUCCESS';
export const GET_ALL_SAFETY_MEASURES_FAILED = 'GET_ALL_SAFETY_MEASURES_FAILED';


// GET ALL SAFETY MEASURES IN CLONE EXISTING MODAL
export const GET_ALL_CLONE_SAFETY_MEASURES_REQUESTED = 'GET_ALL_CLONE_SAFETY_MEASURES_REQUESTED';
export const GET_ALL_CLONE_SAFETY_MEASURES_SUCCESS = 'GET_ALL_CLONE_SAFETY_MEASURES_SUCCESS';
export const GET_ALL_CLONE_SAFETY_MEASURES_FAILED = 'GET_ALL_CLONE_SAFETY_MEASURES_FAILED';


// ADD SAFETY MEASURE
export const ADD_SAFETY_MEASURE_REQUESTED = 'ADD_SAFETY_MEASURE_REQUESTED';
export const ADD_SAFETY_MEASURE_SUCCESS = 'ADD_SAFETY_MEASURE_SUCCESS';
export const ADD_SAFETY_MEASURE_FAILED = 'ADD_SAFETY_MEASURE_FAILED';


// GET SAFETY MEASURE DETAILS
export const SAFETY_MEASURES_DETAILS_REQUESTED = 'SAFETY_MEASURES_DETAILS_REQUESTED';
export const SAFETY_MEASURES_DETAILS_SUCCESS = 'SAFETY_MEASURES_DETAILS_SUCCESS';
export const SAFETY_MEASURES_DETAILS_FAILED = 'SAFETY_MEASURES_DETAILS_FAILED';


// UPDATE SAFETY MEASURE
export const UPDATE_SAFETY_MEASURE_REQUESTED = 'UPDATE_SAFETY_MEASURE_REQUESTED';
export const UPDATE_SAFETY_MEASURE_SUCCESS = 'UPDATE_SAFETY_MEASURE_SUCCESS';
export const UPDATE_SAFETY_MEASURE_FAILED = 'UPDATE_SAFETY_MEASURE_FAILED';


// DELETE SAFETY MEASURE
export const DELETE_SAFETY_MEASURE_REQUESTED = 'DELETE_SAFETY_MEASURE_REQUESTED';
export const DELETE_SAFETY_MEASURE_SUCCESS = 'DELETE_SAFETY_MEASURE_SUCCESS';
export const DELETE_SAFETY_MEASURE_FAILED = 'DELETE_SAFETY_MEASURE_FAILED';


// SAFETY MEASURES MODAL
export const SET_SAFETY_MEASURES_MODAL = "SET_SAFETY_MEASURES_MODAL";

// RESET SAFETY MEASURES ERROR MESSAGES
export const RESET_SAFETY_MEASURES_ERRORS_REQUESTED = "RESET_SAFETY_MEASURES_ERRORS_REQUESTED";

// cloning
export const SET_CLONING_REQUESTED = "SET_CLONING_REQUESTED";

// updating
export const SET_UPDATING_REQUESTED = "SET_UPDATING_REQUESTED";

// CHANGE SEARCH
export const CHANGE_SAFETY_MEASURES_SEARCH_REQUESTED = "CHANGE_SAFETY_MEASURES_SEARCH_REQUESTED";