import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../api/api_instance";

async function getApi(data) {
    try {
        const result = instance({
            url: encodeURI(`v1/model/${data.model_id}/error_code/${data.errorCodeId}/list_procedures`),
            method: "GET",
        }).then((response) => {
            return response;
        })
        return await result;
    } catch (error) {
        throw error;
    }
}

function* fetchErrorCodesProcedures(action) {
    try {
        const res = yield call(getApi, action.payload);
        yield put({ type: 'GET_ALL_EC_PROCEDURES_SUCCESS', proceduresList: res.data });
    } catch (e) {
        yield put({ type: 'GET_ALL_EC_PROCEDURES_FAILED', message: e.message });
    }
}

function* errorCodesProceduresSaga() {
    yield takeEvery('GET_ALL_EC_PROCEDURES_REQUESTED', fetchErrorCodesProcedures);
}

export default errorCodesProceduresSaga;