// GET ALL MODEL PROCEDURES LIST
export const GET_MODEL_PROCEDURE_REQUESTED = 'GET_MODEL_PROCEDURE_REQUESTED';
export const GET_MODEL_PROCEDURE_SUCCESS = 'GET_MODEL_PROCEDURE_SUCCESS';
export const GET_MODEL_PROCEDURE_FAILED = 'GET_MODEL_PROCEDURE_FAILED';

// GET PROCEDURE DETAILS
export const PROCEDURE_DETAILS_REQUESTED = 'PROCEDURE_DETAILS_REQUESTED';
export const PROCEDURE_DETAILS_SUCCESS = 'PROCEDURE_DETAILS_SUCCESS';
export const PROCEDURE_DETAILS_FAILED = 'PROCEDURE_DETAILS_FAILED';

// GET ALL PROCEDURES
export const ALL_PROCEDURE_REQUESTED = 'ALL_PROCEDURE_REQUESTED';
export const ALL_PROCEDURE_SUCCESS = 'ALL_PROCEDURE_SUCCESS';
export const ALL_PROCEDURE_FAILED = 'ALL_PROCEDURE_FAILED';

// ADD A PROCEDURE
export const ADD_PROCEDURE_REQUESTED = 'ADD_PROCEDURE_REQUESTED';
export const ADD_PROCEDURE_SUCCESS = 'ADD_PROCEDURE_SUCCESS';
export const ADD_PROCEDURE_FAILED = 'ADD_PROCEDURE_FAILED';

// DELETE A PROCEDURE
export const DELETE_PROCEDURE_REQUESTED = 'DELETE_PROCEDURE_REQUESTED';
export const DELETE_PROCEDURE_SUCCESS = 'DELETE_PROCEDURE_SUCCESS';
export const DELETE_PROCEDURE_FAILED = 'DELETE_PROCEDURE_FAILED';

// UPDATE A PROCEDURE STEP
export const UPDATE_PROCEDURE_STEP_REQUESTED = 'UPDATE_PROCEDURE_STEP_REQUESTED';
export const UPDATE_PROCEDURE_STEP_SUCCESS = 'UPDATE_PROCEDURE_STEP_SUCCESS';
export const UPDATE_PROCEDURE_STEP_FAILED = 'UPDATE_PROCEDURE_STEP_FAILED';

// ADD A PROCEDURE STEP
export const ADD_PROCEDURE_STEP_REQUESTED = 'ADD_PROCEDURE_STEP_REQUESTED';
export const ADD_PROCEDURE_STEP_SUCCESS = 'ADD_PROCEDURE_STEP_SUCCESS';
export const ADD_PROCEDURE_STEP_FAILED = 'ADD_PROCEDURE_STEP_FAILED';

// DELETE A PROCEDURE STEP
export const DELETE_PROCEDURE_STEP_REQUESTED = 'DELETE_PROCEDURE_STEP_REQUESTED';
export const DELETE_PROCEDURE_STEP_SUCCESS = 'DELETE_PROCEDURE_STEP_SUCCESS';
export const DELETE_PROCEDURE_STEP_FAILED = 'DELETE_PROCEDURE_STEP_FAILED';

// SET TO DEFAULT
export const SET_TO_DEFAULT_REQUESTED = 'SET_TO_DEFAULT_REQUESTED';
export const SET_TO_DEFAULT_SUCCESS = 'SET_TO_DEFAULT_SUCCESS';
export const SET_TO_DEFAULT_FAILED = 'SET_TO_DEFAULT_FAILED';

// UPDATE A PROCEDURE STEP ORDER
export const UPDATE_PROCEDURE_STEP_ORDER_REQUESTED = 'UPDATE_PROCEDURE_STEP_ORDER_REQUESTED';
export const UPDATE_PROCEDURE_STEP_ORDER_SUCCESS = 'UPDATE_PROCEDURE_STEP_ORDER_SUCCESS';
export const UPDATE_PROCEDURE_STEP_ORDER_FAILED = 'UPDATE_PROCEDURE_STEP_ORDER_FAILED';

// UPDATE PROCEDURE
export const UPDATE_PROCEDURE_REQUESTED = 'UPDATE_PROCEDURE_REQUESTED';
export const UPDATE_PROCEDURE_SUCCESS = 'UPDATE_PROCEDURE_SUCCESS';
export const UPDATE_PROCEDURE_FAILED = 'UPDATE_PROCEDURE_FAILED';

// STOP SUCCESS TOASTR
// export const STOP_SHOW_TOAST_SUCCESS = 'STOP_SHOW_TOAST_SUCCESS';

// RESET PROCEDURE ERROR MESSAGES
export const RESET_PROCEDURE_ERROR_REQUESTED = "RESET_PROCEDURE_ERROR_REQUESTED";

// PROCEDURE MODAL
export const SET_PROCEDURE_MODAL = "SET_PROCEDURE_MODAL";

// PROCEDURE DETAIL MODAL
export const SET_PROCEDURE_DETAIL_MODAL = "SET_PROCEDURE_DETAIL_MODAL";

// CHANGE SEARCH
export const CHANGE_PROCEDURES_SEARCH_REQUESTED = "CHANGE_PROCEDURES_SEARCH_REQUESTED";

// CHANGE MODEL PROCEDURE SEARCH
export const CHANGE_MODEL_PROCEDURES_SEARCH_REQUESTED = "CHANGE_MODEL_PROCEDURES_SEARCH_REQUESTED";