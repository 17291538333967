// GET ALL PRIMARY CATEGORIES
export const GET_PRIMARY_CATEGORIES_REQUESTED = "GET_PRIMARY_CATEGORIES_REQUESTED";
export const GET_PRIMARY_CATEGORIES_SUCCESS = "GET_PRIMARY_CATEGORIES_SUCCESS";
export const GET_PRIMARY_CATEGORIES_FAILED = "GET_PRIMARY_CATEGORIES_FAILED";

// GET ALL SECONDARY CATEGORIES
export const GET_SECONDARY_CATEGORIES_REQUESTED = "GET_SECONDARY_CATEGORIES_REQUESTED";
export const GET_SECONDARY_CATEGORIES_SUCCESS = "GET_SECONDARY_CATEGORIES_SUCCESS";
export const GET_SECONDARY_CATEGORIES_FAILED = "GET_SECONDARY_CATEGORIES_FAILED";

// GET ALL SECONDARY CATEGORIES DROPDOWN
export const GET_SECONDARY_CATEGORIES_IN_DROPDOWN_REQUESTED = "GET_SECONDARY_CATEGORIES_IN_DROPDOWN_REQUESTED";
export const GET_SECONDARY_CATEGORIES_IN_DROPDOWN_SUCCESS = "GET_SECONDARY_CATEGORIES_IN_DROPDOWN_SUCCESS";
export const GET_SECONDARY_CATEGORIES_IN_DROPDOWN_FAILED = "GET_SECONDARY_CATEGORIES_IN_DROPDOWN_FAILED";

// CREATE CATEGORY
export const CREATE_CATEGORIES_REQUESTED = "CREATE_CATEGORIES_REQUESTED";
export const CREATE_CATEGORIES_SUCCESS = "CREATE_CATEGORIES_SUCCESS";
export const CREATE_CATEGORIES_FAILED = "CREATE_CATEGORIES_FAILED";

// GET ALL CATEGORIES LIST
export const ALL_CATEGORIES_REQUESTED = "ALL_CATEGORIES_REQUESTED";
export const ALL_CATEGORIES_SUCCESS = "ALL_CATEGORIES_SUCCESS";
export const ALL_CATEGORIES_FAILED = "ALL_CATEGORIES_FAILED";

// EDIT CATEGORY
export const EDIT_CATEGORIES_REQUESTED = "EDIT_CATEGORIES_REQUESTED";
export const EDIT_CATEGORIES_SUCCESS = "EDIT_CATEGORIES_SUCCESS";
export const EDIT_CATEGORIES_FAILED = "EDIT_CATEGORIES_FAILED";

// DELETE CATEGORY
export const DELETE_CATEGORIES_REQUESTED = "DELETE_CATEGORIES_REQUESTED";
export const DELETE_CATEGORIES_SUCCESS = "DELETE_CATEGORIES_SUCCESS";
export const DELETE_CATEGORIES_FAILED = "DELETE_CATEGORIES_FAILED";

// EDIT SECONDARY CATEGORIES
export const EDIT_SECONDARY_CATEGORIES_REQUESTED = "EDIT_SECONDARY_CATEGORIES_REQUESTED";
export const EDIT_SECONDARY_CATEGORIES_SUCCESS = "EDIT_SECONDARY_CATEGORIES_SUCCESS";
export const EDIT_SECONDARY_CATEGORIES_FAILED = "EDIT_SECONDARY_CATEGORIES_FAILED";

// EDIT MODEL PRIMARY CATEGORIES
export const EDIT_MODAL_CATEGORIES_REQUESTED = "EDIT_MODAL_CATEGORIES_REQUESTED";

// RESET FORM ON CLOSE
export const RESET_ERROR_REQUESTED = "RESET_ERROR_REQUESTED";

// EDIT MODEL SECONDARY CATEGORIES
export const EDIT_MODAL_SECONDARY_CATEGORIES_REQUESTED = "EDIT_MODAL_SECONDARY_CATEGORIES_REQUESTED";

// ASSIGN TO MODEL WHILE DELETING CATEGORY
export const CHANGE_MODEL_AND_DELETE = "CHANGE_MODEL_AND_DELETE";

// GET EVERY CATEGORY (PRIMARY & SECONDARY)
export const GET_EVERY_CATEGORY_REQUESTED = "GET_EVERY_CATEGORY_REQUESTED";
export const GET_EVERY_CATEGORY_SUCCESS = "GET_EVERY_CATEGORY_SUCCESS";
export const GET_EVERY_CATEGORY_FAILED = "GET_EVERY_CATEGORY_FAILED";

// CHANGE SEARCH
export const CHANGE_CATEGORIES_SEARCH_REQUESTED = "CHANGE_CATEGORIES_SEARCH_REQUESTED";


