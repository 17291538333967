export const PartConstant = [
  {
    partName: "Edge Locator",
    partNumber: "050A17-15",
    partDescription: "Edge Locator",
    objectName: "T4edgelocator11X-003",
  },
  {
    partName: "X Axis Base",
    partNumber: "37M800A-004",
    partDescription: "X Axis Base",
    objectName: "Xaxisbase11X-003",
  },
  {
    partName:
      "Operation panel including emergency stop for lift-up chip conveyor",
    partNumber: "Operation Panel",
    partDescription:
      "Operation panel including emergency stop for lift-up chip conveyor",
    objectName: "EmergencyStopB",
  },
  {
    partName: "Tank Cover",
    partNumber: "110A58",
    partDescription: "Tank Cover",
    objectName: "110A58TankCover-001",
  },
  {
    partName: "Stroke Envelope",
    partNumber: "Stroke Envelope",
    partDescription: "Stroke Envelope",
    objectName: "T4strokeenvelope11X-003",
  },
  {
    partName: "Oil Temperature Controller",
    partNumber: "37M080A",
    partDescription: "Oil Temperature Controller",
    objectName: "OilTempController37M080A-",
  },
  {
    partName: "Work Desk",
    partNumber: "Desk",
    partDescription: "Work Desk",
    objectName: "Desk-",
  },
  {
    partName: "Hyd. Air and Lubrication Unit",
    partNumber: "37M070A",
    partDescription: "Hyd. Air and Lubrication Unit",
    objectName: "HydAirLubUnit37M070A-",
  },
  {
    partName: "Operation panel including emergency stop for APC",
    partNumber: "Operation Panel",
    partDescription: "Operation panel including emergency stop for APC",
    objectName: "EmergencyStopA",
  },
  {
    partName: "Nabell Bellows Cover, 800A11-3/4",
    partNumber: "AX72108004A",
    partDescription: "Nabell Bellows Cover, 800A11-3/4",
    objectName: "BellowsCover-",
  },
  {
    partName: "APC with tilting WSS",
    partNumber: "37M130A",
    partDescription: "APC with tilting WSS",
    objectName: "APCWithTiltingWSS37M130A-",
  },
  {
    partName: "Coolant Pump",
    partNumber: "M6A/110A203",
    partDescription: "Coolant Pump",
    objectName: "M6AxTSCCOOLANTPUMP110A203CoolantPump- 1",
  },
  {
    partName:
      "Mist Collector. Also contains HEPA filter P030625 (Nippon Donaldson) and V bag filter P199369 (Nippon Donaldson).",
    partNumber: "ZNDL002-1-9, 37M126A",
    partDescription:
      "Mist Collector. Also contains HEPA filter P030625 (Nippon Donaldson) and V bag filter P199369 (Nippon Donaldson).",
    objectName: "MistCollectorZNDL00219-",
  },
  {
    partName: "Coolant Pump",
    partNumber: "M6A/110A203",
    partDescription: "Coolant Pump",
    objectName: "M6AxTSCCOOLANTPUMP110A203CoolantPump-",
  },
  {
    partName: "Pallet",
    partNumber: "37M055A-009",
    partDescription: "Pallet",
    objectName: "T4palletMODELSOLIDS11X-003",
  },
  {
    partName: "Column",
    partNumber: "37M020B-001",
    partDescription: "Column",
    objectName: "T4Column11X-003",
  },
  {
    partName: "Outside Duct Area",
    partNumber: "37M330A",
    partDescription: "Outside Duct Area",
    objectName: "OutsideDuct-",
  },
  {
    partName: "TSC Pressure Switch",
    partNumber: "L51338",
    partDescription: "TSC Pressure Switch",
    objectName: "TSCPressureSwitchxL51338-",
  },
  {
    partName: "Main Power Switch",
    partNumber: "Main Power Switch",
    partDescription: "Main Power Switch",
    objectName: "MainPowerSwitch-",
  },
  {
    partName: "Hyd. Air and Lubrication Unit",
    partNumber: "37M070A",
    partDescription: "Hyd. Air and Lubrication Unit",
    objectName: "HydAirLubUnit37M070Ax070A12x070A7-",
  },
  {
    partName: "Rear Bed, Conveyor",
    partNumber: "37M030B-007",
    partDescription: "Rear Bed, Conveyor",
    objectName: "RearBed-",
  },
  {
    partName: 'A Axis "Swing Head" with 110 degree swing capability.',
    partNumber: "37M012A",
    partDescription: 'A Axis "Swing Head" with 110 degree swing capability.',
    objectName: "LM37082AAXIS11X-",
  },
  {
    partName: "TSC Relief 1",
    partNumber: "SOL13071",
    partDescription: "TSC Relief 1",
    objectName: "SOL13071TSCRelief1-",
  },
  {
    partName: "Cover",
    partNumber: "110A1114",
    partDescription: "Cover",
    objectName: "110A1114Cover-",
  },
  {
    partName: "Tank Cover",
    partNumber: "110A58",
    partDescription: "Tank Cover",
    objectName: "110A58TankCover-",
  },
  {
    partName: "Pressure Hose and Nozzle Equipment",
    partNumber: "110A708",
    partDescription: "Pressure Hose and Nozzle Equipment",
    objectName: "110A708PressureHose-",
  },
  {
    partName: "Table",
    partNumber: "37M050A-039",
    partDescription: "Table",
    objectName: "LM37082TABLE11X-272",
  },
  {
    partName: "Work Envelope",
    partNumber: "Work Envelope",
    partDescription: "Work Envelope",
    objectName: "T4workenvelope11X-003",
  },
  {
    partName: "TSC Supply and Discharge",
    partNumber: "SOL1306",
    partDescription: "TSC Supply and Discharge",
    objectName: "SOL1306TSCSupplyAndDischarge-",
  },
  {
    partName: "TSC Relief 2",
    partNumber: "SOL13072",
    partDescription: "TSC Relief 2",
    objectName: "SOL13072TSCRelief2-",
  },
  {
    partName: "2nd Tank Lower Level Detect",
    partNumber: "LS184",
    partDescription: "2nd Tank Lower Level Detect",
    objectName: "LS1842ndTankLowerLevelDetect-",
  },
  {
    partName: "Splash Guard (Bot)",
    partNumber: "37M170C",
    partDescription: "Splash Guard (Bot)",
    objectName: "LM37082SG211BOTX-105",
  },
  {
    partName: "Pressure Hose and Nozzle Equipment",
    partNumber: "110A708",
    partDescription: "Pressure Hose and Nozzle Equipment",
    objectName: "110A708PressureHose-002",
  },
  {
    partName: "Coolant Pump C-Axis",
    partNumber: "M21",
    partDescription: "Coolant Pump C-Axis",
    objectName: "M21NozzleCoolantPumpCAxis-",
  },
  {
    partName: "Cabinet",
    partNumber: "Cabinet",
    partDescription: "Cabinet",
    objectName: "Cabinet0-",
  },
  {
    partName: "Tool Loading Station",
    partNumber: "37M069B",
    partDescription: "Tool Loading Station",
    objectName: "ToolLoading37M069B-",
  },
  {
    partName: "Chip Conveyor",
    partNumber: "37M121A",
    partDescription: "Chip Conveyor",
    objectName: "ChipConveyor37M121A-",
  },
  {
    partName: "Splash Guard (Top)",
    partNumber: "37M170C",
    partDescription: "Splash Guard (Top)",
    objectName: "LM37082SG111TOPX-305",
  },
  {
    partName: "Hydraulic Pump Access Panel",
    partNumber: "Hydraulic Pump Access Panel",
    partDescription: "Hydraulic Pump Access Panel",
    objectName: "HydPumpPanel",
  },
  {
    partName: "Coolant Pump",
    partNumber: "M2/110A204",
    partDescription: "Coolant Pump",
    objectName: "M2NozzleCoolantPump110A204CoolantPump-",
  },
  {
    partName:
      "C Axis, can rotate 360 degrees. It provides the means to swing the A axis swing head and spindle assembly around a centerline which parallels the Z axis motion.",
    partNumber: "37M013B",
    partDescription:
      "C Axis, can rotate 360 degrees. It provides the means to swing the A axis swing head and spindle assembly around a centerline which parallels the Z axis motion.",
    objectName: "LM37082CAXIS11X-1030",
  },
  {
    partName: "Operation Panel",
    partNumber: "Operation Panel 750A4-2",
    partDescription: "Operation Panel",
    objectName: "OperationPanel",
  },
  {
    partName: "Operation panel including emergency stop for ATC",
    partNumber: "Operation Panel 063A131-2",
    partDescription: "Operation panel including emergency stop for ATC",
    objectName: "EmergencyStopC",
  },
  {
    partName: "Coolant Filter Element",
    partNumber: "110A4113/FS12100SWF",
    partDescription: "Coolant Filter Element",
    objectName: "110A4113FilterElementFS12100SWF-002",
  },
  {
    partName: "Battery Access",
    partNumber: "Battery Access",
    partDescription: "Battery Access",
    objectName: "BatteryAccess",
  },
  {
    partName: "1st Tank Lower Level Detect",
    partNumber: "LS1319",
    partDescription: "1st Tank Lower Level Detect",
    objectName: "LS1319FirstTankLowerLevelDetect-",
  },
  {
    partName: "Electrical Equipment",
    partNumber: "37M090A",
    partDescription: "Electrical Equipment",
    objectName: "ElectricalEquipment37M090A-",
  },
  {
    partName: "Coolant Filter Element",
    partNumber: "110A4113/FS12100SWF",
    partDescription: "Coolant Filter Element",
    objectName: "110A4113FilterElementFS12100SWF-001",
  },
  {
    partName: "Spindle",
    partNumber: "37M011A01",
    partDescription: "Spindle",
    objectName: "LM37082SPINDLE11X-352",
  },
  {
    partName: "Stop Block",
    partNumber: "Stop Block",
    partDescription: "Stop Block",
    objectName: "T4stopblock11X-003",
  },
  {
    partName: "Duct",
    partNumber: "110A1514",
    partDescription: "Duct",
    objectName: "110A1514Duct-",
  },
  {
    partName: "Super Sun-Braid Hose",
    partNumber: "110A709",
    partDescription: "Super Sun-Braid Hose",
    objectName: "110A709SuperSunBraidHose-",
  },
];
