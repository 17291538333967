// GET ALL SECTIONS
export const GET_ALL_SECTIONS_REQUESTED = 'GET_ALL_SECTIONS_REQUESTED';
export const GET_ALL_SECTIONS_SUCCESS = 'GET_ALL_SECTIONS_SUCCESS';
export const GET_ALL_SECTIONS_FAILED = 'GET_ALL_SECTIONS_FAILED'

// CREATE A SECTION
export const ADD_SECTION_REQUESTED = 'ADD_SECTION_REQUESTED';
export const ADD_SECTION_SUCCESS = 'ADD_SECTION_SUCCESS';
export const ADD_SECTION_FAILED = 'ADD_SECTION_FAILED';

// GET A SECTION DETAILS
export const SECTION_DETAILS_REQUESTED = 'SECTION_DETAILS_REQUESTED';
export const SECTION_DETAILS_SUCCESS = 'SECTION_DETAILS_SUCCESS';
export const SECTION_DETAILS_FAILED = 'SECTION_DETAILS_FAILED';

// UPDATE A SECTION
export const UPDATE_SECTION_REQUESTED = 'UPDATE_SECTION_REQUESTED';
export const UPDATE_SECTION_SUCCESS = 'UPDATE_SECTION_SUCCESS';
export const UPDATE_SECTION_FAILED = 'UPDATE_SECTION_FAILED';

// DELETE A SECTION
export const DELETE_SECTION_REQUESTED = 'DELETE_SECTION_REQUESTED';
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS';
export const DELETE_SECTION_FAILED = 'DELETE_SECTION_FAILED';

// GET ALL SECTION NON PAGEINATE
export const NO_PAGINATE_SECTIONS_REQUESTED = 'NO_PAGINATE_SECTIONS_REQUESTED';
export const NO_PAGINATE_SECTIONS_SUCCESS = 'NO_PAGINATE_SECTIONS_SUCCESS';
export const NO_PAGINATE_SECTIONS_FAILED = 'NO_PAGINATE_SECTIONS_FAILED';

// SECTION MODAL
export const SET_SECTION_MODAL_REQUESTED = "SET_SECTION_MODAL_REQUESTED";

// RESET THE DUPLICATE ERROR MESSAGE
export const RESET_SECTIONS_ERRORS_REQUESTED = "RESET_SECTIONS_ERRORS_REQUESTED";

// CHANGE SEARCH
export const CHANGE_SECTIONS_SEARCH_REQUESTED = "CHANGE_SECTIONS_SEARCH_REQUESTED";