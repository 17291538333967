// GET ALL ASSET NOTES
export const GET_ALL_ASSET_NOTES_REQUESTED = "GET_ALL_ASSET_NOTES_REQUESTED";
export const GET_ALL_ASSET_NOTES_SUCCESS = "GET_ALL_ASSET_NOTES_SUCCESS";
export const GET_ALL_ASSET_NOTES_FAILED = "GET_ALL_ASSET_NOTES_FAILED";


// ADD AN ASSET NOTE
export const ADD_ASSET_NOTE_REQUESTED = "ADD_ASSET_NOTE_REQUESTED";
export const ADD_ASSET_NOTE_SUCCESS = "ADD_ASSET_NOTE_SUCCESS";
export const ADD_ASSET_NOTE_FAILED = "ADD_ASSET_NOTE_FAILED";


// GET AN ASSET NOTE DETAILS
export const GET_ASSET_NOTE_DETAILS_REQUESTED = "GET_ASSET_NOTE_DETAILS_REQUESTED";
export const GET_ASSET_NOTE_DETAILS_SUCCESS = "GET_ASSET_NOTE_DETAILS_SUCCESS";
export const GET_ASSET_NOTE_DETAILS_FAILED = "GET_ASSET_NOTE_DETAILS_FAILED";


// UPDATE AN ASSET NOTE
export const UPDATE_ASSET_NOTE_REQUESTED = "UPDATE_ASSET_NOTE_REQUESTED";
export const UPDATE_ASSET_NOTE_SUCCESS = "UPDATE_ASSET_NOTE_SUCCESS";
export const UPDATE_ASSET_NOTE_FAILED = "UPDATE_ASSET_NOTE_FAILED";


// DELETE AN ASSET NOTE
export const DELETE_ASSET_NOTE_REQUESTED = "DELETE_ASSET_NOTE_REQUESTED";
export const DELETE_ASSET_NOTE_SUCCESS = "DELETE_ASSET_NOTE_SUCCESS"
export const DELETE_ASSET_NOTE_FAILED = "DELETE_ASSET_NOTE_FAILED";

// SET ASSET NOTES MODAL
export const SET_ASSET_NOTES_MODAL_REQUESTED = "SET_ASSET_NOTES_MODAL_REQUESTED";

// RESET THE ERRORS - MESSAGES
export const RESET_ASSET_NOTES_ERRORS_REQUESTED = "RESET_ASSET_NOTES_ERRORS_REQUESTED";

// CHANGE SEARCH
export const CHANGE_ASSET_NOTES_SEARCH_REQUESTED = "CHANGE_ASSET_NOTES_SEARCH_REQUESTED";