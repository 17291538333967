// GET ALL TROUBLESHOOT
export const GET_ALL_TROUBLESHOOT_REQUESTED = 'GET_ALL_TROUBLESHOOT_REQUESTED';
export const GET_ALL_TROUBLESHOOT_SUCCESS = 'GET_ALL_TROUBLESHOOT_SUCCESS';
export const GET_ALL_TROUBLESHOOT_FAILED = 'GET_ALL_TROUBLESHOOT_FAILED';

// ADD A TROUBLESHOOT
export const ADD_TROUBLESHOOT_REQUESTED = 'ADD_TROUBLESHOOT_REQUESTED';
export const ADD_TROUBLESHOOT_SUCCESS = 'ADD_TROUBLESHOOT_SUCCESS';
export const ADD_TROUBLESHOOT_FAILED = 'ADD_TROUBLESHOOT_FAILED';

// GET TROUBLESHOOT DETAILS
export const TROUBLESHOOT_DETAILS_REQUESTED = 'TROUBLESHOOT_DETAILS_REQUESTED';
export const TROUBLESHOOT_DETAILS_SUCCESS = 'TROUBLESHOOT_DETAILS_SUCCESS';
export const TROUBLESHOOT_DETAILS_FAILED = 'TROUBLESHOOT_DETAILS_FAILED';

// UPDATE TROUBLESHOOT
export const UPDATE_TROUBLESHOOT_REQUESTED = 'UPDATE_TROUBLESHOOT_REQUESTED';
export const UPDATE_TROUBLESHOOT_SUCCESS = 'UPDATE_TROUBLESHOOT_SUCCESS';
export const UPDATE_TROUBLESHOOT_FAILED = 'UPDATE_TROUBLESHOOT_FAILED';

// DELETE TROUBLESHOOT
export const DELETE_TROUBLESHOOT_REQUESTED = 'DELETE_TROUBLESHOOT_REQUESTED';
export const DELETE_TROUBLESHOOT_SUCCESS = 'DELETE_TROUBLESHOOT_SUCCESS';
export const DELETE_TROUBLESHOOT_FAILED = 'DELETE_TROUBLESHOOT_FAILED';


// TROUBLESHOOT STEPS =============================================================

//  ADD TROUBLESHOOT STEP
export const ADD_TROUBLESHOOT_STEP_REQUESTED = 'ADD_TROUBLESHOOT_STEP_REQUESTED';
export const ADD_TROUBLESHOOT_STEP_SUCCESS = 'ADD_TROUBLESHOOT_STEP_SUCCESS';
export const ADD_TROUBLESHOOT_STEP_FAILED = 'ADD_TROUBLESHOOT_STEP_FAILED';

// UPDATE TROUBLESHOOT STEP
export const UPDATE_TROUBLESHOOT_STEP_REQUESTED = 'UPDATE_TROUBLESHOOT_STEP_REQUESTED';
export const UPDATE_TROUBLESHOOT_STEP_SUCCESS = 'UPDATE_TROUBLESHOOT_STEP_SUCCESS';
export const UPDATE_TROUBLESHOOT_STEP_FAILED = 'UPDATE_TROUBLESHOOT_STEP_FAILED';

// DELETE TROUBLESHOOT STEP
export const DELETE_TROUBLESHOOT_STEP_REQUESTED = 'DELETE_TROUBLESHOOT_STEP_REQUESTED';
export const DELETE_TROUBLESHOOT_STEP_SUCCESS = 'DELETE_TROUBLESHOOT_STEP_SUCCESS';
export const DELETE_TROUBLESHOOT_STEP_FAILED = 'DELETE_TROUBLESHOOT_STEP_FAILED';

// UPDATE TROUBLESHOOT STEP ORDER
export const UPDATE_TROUBLESHOOT_STEP_ORDER_REQUESTED = 'UPDATE_TROUBLESHOOT_STEP_ORDER_REQUESTED';
export const UPDATE_TROUBLESHOOT_STEP_ORDER_SUCCESS = 'UPDATE_TROUBLESHOOT_STEP_ORDER_SUCCESS';
export const UPDATE_TROUBLESHOOT_STEP_ORDER_FAILED = 'UPDATE_TROUBLESHOOT_STEP_ORDER_FAILED';

// SET TO DEFAULT STEP
export const SET_TO_DEFAULT_TROUBLESHOOT_STEP_REQUESTED = 'SET_TO_DEFAULT_TROUBLESHOOT_STEP_REQUESTED';
export const SET_TO_DEFAULT_TROUBLESHOOT_STEP_SUCCESS = 'SET_TO_DEFAULT_TROUBLESHOOT_STEP_SUCCESS';
export const SET_TO_DEFAULT_TROUBLESHOOT_STEP_FAILED = 'SET_TO_DEFAULT_TROUBLESHOOT_STEP_FAILED';

// APPROVE THE STEP
export const APPROVE_TROUBLESHOOT_STEP_REQUESTED = 'APPROVE_TROUBLESHOOT_STEP_REQUESTED';
export const APPROVE_TROUBLESHOOT_STEP_SUCCESS = 'APPROVE_TROUBLESHOOT_STEP_SUCCESS';
export const APPROVE_TROUBLESHOOT_STEP_FAILED = 'APPROVE_TROUBLESHOOT_STEP_FAILED';


// TROUBLESHOOT MODAL
export const SET_TOUBLESHOOT_MODAL = "SET_TOUBLESHOOT_MODAL";

// TROUBLESHOOT DETAIL MODAL
export const SET_TROUBLESHOOT_DETAIL_MODAL = "SET_TROUBLESHOOT_DETAIL_MODAL";

// RESET TROUBLESHOOT ERROR MESSAGES
export const RESET_TROUBLESHOOT_ERRORS_REQUESTED = "RESET_TROUBLESHOOT_ERRORS_REQUESTED";

// CHANGE SEARCH
export const CHANGE_TROUBLESHOOT_SEARCH_REQUESTED = "CHANGE_TROUBLESHOOT_SEARCH_REQUESTED";