// Get All User Roles
export const GET_ALL_USERS_ROLES_REQUESTED = 'GET_ALL_USERS_ROLES_REQUESTED';
export const GET_ALL_USERS_ROLES_SUCCESS = 'GET_ALL_USERS_ROLES_SUCCESS';
export const GET_ALL_USERS_ROLES_FAILED = 'GET_ALL_USERS_ROLES_FAILED';


// Get All User Role Permissions
export const GET_ALL_PERMISSIONS_REQUESTED = "GET_ALL_PERMISSIONS_REQUESTED";
export const GET_ALL_PERMISSIONS_SUCCESS = "GET_ALL_PERMISSIONS_SUCCESS";
export const GET_ALL_PERMISSIONS_FAILED = "GET_ALL_PERMISSIONS_FAILED";

// Add User Role Permission
export const ADD_USER_ROLES_REQUESTED = "ADD_USER_ROLES_REQUESTED";
export const ADD_USER_ROLES_SUCCESS = "ADD_USER_ROLES_SUCCESS";
export const ADD_USER_ROLES_FAILED = "ADD_USER_ROLES_FAILED";

// Get a User Role Permission
export const GET_USER_ROLE_DETAILS_REQUESTED = "GET_USER_ROLE_DETAILS_REQUESTED";
export const GET_USER_ROLE_DETAILS_SUCCESS = "GET_USER_ROLE_DETAILS_SUCCESS";
export const GET_USER_ROLE_DETAILS_FAILED = "GET_USER_ROLE_DETAILS_FAILED";

// Update User Role Permissions
export const UPDATE_USER_ROLES_REQUESTED = "UPDATE_USER_ROLES_REQUESTED";
export const UPDATE_USER_ROLES_SUCCESS = "UPDATE_USER_ROLES_SUCCESS";
export const UPDATE_USER_ROLES_FAILED = "UPDATE_USER_ROLES_FAILED";


// RESET USER ROLE MESSAGES
export const RESET_USER_ROLE_ERRORS_REQUESTED = "RESET_USER_ROLE_ERRORS_REQUESTED";

// CHANGE SEARCH IN USER ROLES
export const CHANGE_USER_ROLES_SEARCH_REQUESTED = "CHANGE_USER_ROLES_SEARCH_REQUESTED";

// CHANGE SEARCH OF USER ROLES IN ADD/EDIT USER
export const CHANGE_USER_ROLE_SEARCH_IN_USERS_REQUESTED = "CHANGE_USER_ROLE_SEARCH_IN_USERS_REQUESTED";