// TRIGGER OTP
export const TRIGGER_OTP_REQUESTED = "TRIGGER_OTP_REQUESTED";
export const TRIGGER_OTP_SUCCESS = "TRIGGER_OTP_SUCCESS";
export const TRIGGER_OTP_FAILED = "TRIGGER_OTP_FAILED";
//VERIFY OTP
export const VERIFY_OTP_REQUESTED = "VERIFY_OTP_REQUESTED";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED";

//RESET PASSWORD
export const RESET_PASSWORD_REQUESTED = "RESET_PASSWORD_REQUESTED";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

// RESET ERRORS
export const RESET_PASSWORD_ERROR_RESET_REQUESTED = "RESET_PASSWORD_ERROR_RESET_REQUESTED";



