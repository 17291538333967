// GET ALL NOTIFICATIONS
export const GET_ALL_NOTIFICATIONS_REQUESTED = "GET_ALL_NOTIFICATIONS_REQUESTED";
export const GET_ALL_NOTIFICATIONS_SUCCESS = "GET_ALL_NOTIFICATIONS_SUCCESS";
export const GET_ALL_NOTIFICATIONS_FAILED = "GET_ALL_NOTIFICATIONS_FAILED";

// CLEAR ALL NOTIFICATIONS
export const CLEAR_ALL_NOTIFICATIONS_REQUESTED = "CLEAR_ALL_NOTIFICATIONS_REQUESTED";
export const CLEAR_ALL_NOTIFICATIONS_SUCCESS = "CLEAR_ALL_NOTIFICATIONS_SUCCESS";
export const CLEAR_ALL_NOTIFICATIONS_FAILED = "CLEAR_ALL_NOTIFICATIONS_FAILED";

// UNREAD NOTIFICATION COUNT
export const UNREAD_NOTIFICATION_COUNT_REQUESTED = "UNREAD_NOTIFICATION_COUNT_REQUESTED";
export const UNREAD_NOTIFICATION_COUNT_SUCCESS = "UNREAD_NOTIFICATION_COUNT_SUCCESS";
export const UNREAD_NOTIFICATION_COUNT_FAILED = "UNREAD_NOTIFICATION_COUNT_FAILED";

// UNREAD NOTIFICATION COUNT
export const SAVE_CLIENT_TOKEN_REQUESTED = "SAVE_CLIENT_TOKEN_REQUESTED";
export const SAVE_CLIENT_TOKEN_SUCCESS = "SAVE_CLIENT_TOKEN_SUCCESS";
export const SAVE_CLIENT_TOKEN_FAILED = "SAVE_CLIENT_TOKEN_FAILED";