import { call, put, takeEvery } from "redux-saga/effects";
import instance from "../../../api/api_instance";
import { SET_TOASTER_SUCCESS } from "../toaster/types";
import {
  DELETE_DATABASE_FAILED,
  DELETE_DATABASE_SUCCESS,
  GET_ALL_DATABASES_REQUESTED,
} from "./types";
import { DELETE_SECTION_REQUESTED } from "../sections/types";

async function getApi(data) {
  try {
    const result = instance({
      url: `v1/database/${data.id}`,
      method: "DELETE",
    }).then((response) => {
      return response;
    });
    return await result;
  } catch (error) {
    throw error;
  }
}

function* deleteDatabase(action) {
  const data = {
    search: "",
    page: 0,
    limit: 10,
  };

  try {
    const res = yield call(getApi, action.payload);
    const toastrData = {
      content: `${res.data.title} database deleted Successfully`,
      type: "success",
    };
    yield put({ type: DELETE_DATABASE_SUCCESS, sectionsList: res.data });
    yield put({ type: GET_ALL_DATABASES_REQUESTED, payload: data });
    yield put({ type: SET_TOASTER_SUCCESS, data: toastrData });
  } catch (e) {
    const toastrData = {
      content: "Failed to delete this Database",
      type: "failed",
    };
    if (e.response.status === 406 || e.response.status === 404) {
      yield put({ type: DELETE_DATABASE_FAILED, message: e.response.data });
      yield put({ type: SET_TOASTER_SUCCESS, data: toastrData });
    } else {
      yield put({
        type: DELETE_DATABASE_FAILED,
        message: "Some error occurred",
      });
      yield put({ type: SET_TOASTER_SUCCESS, data: toastrData });
    }

    if (e.response.status === 500) {
      const toastrFailedData = {
        content: e.response.data.errors
          ? e.response.data.errors
          : "Something went wrong!",
        type: "failed",
      };
      yield put({ type: SET_TOASTER_SUCCESS, data: toastrFailedData });
    }
  }
}

export function* deleteDatabaseSaga() {
  yield takeEvery(DELETE_SECTION_REQUESTED, deleteDatabase);
}
