// Get All Devices List
export const GET_ALL_DEVICES_REQUESTED = 'GET_ALL_DEVICES_REQUESTED';
export const GET_ALL_DEVICES_SUCCESS = 'GET_ALL_DEVICES_SUCCESS';
export const GET_ALL_DEVICES_FAILED = 'GET_ALL_DEVICES_FAILED';

// Get Active Devices List
export const GET_ACTIVE_DEVICES_REQUESTED = 'GET_ACTIVE_DEVICES_REQUESTED';
export const GET_ACTIVE_DEVICES_SUCCESS = 'GET_ACTIVE_DEVICES_SUCCESS';
export const GET_ACTIVE_DEVICES_FAILED = 'GET_ACTIVE_DEVICES_FAILED';

// Get Draft Devices List
export const GET_DRAFT_DEVICES_REQUESTED = 'GET_DRAFT_DEVICES_REQUESTED';
export const GET_DRAFT_DEVICES_SUCCESS = 'GET_DRAFT_DEVICES_SUCCESS';
export const GET_DRAFT_DEVICES_FAILED = 'GET_DRAFT_DEVICES_FAILED';

// Get All Device Details
export const DEVICE_DETAILS_REQUESTED = 'DEVICE_DETAILS_REQUESTED';
export const DEVICE_DETAILS_SUCCESS = 'DEVICE_DETAILS_SUCCESS';
export const DEVICE_DETAILS_FAILED = 'DEVICE_DETAILS_FAILED';

// Create/Add a Device
export const POST_DEVICE_REQUESTED = "POST_DEVICE_REQUESTED";
export const POST_DEVICE_SUCCESS = "POST_DEVICE_SUCCESS";
export const POST_DEVICE_FAILED = "POST_DEVICE_FAILED";

// Update a Device
export const EDIT_DEVICE_REQUESTED = "EDIT_DEVICE_REQUESTED";
export const EDIT_DEVICE_SUCCESS = "EDIT_DEVICE_SUCCESS";
export const EDIT_DEVICE_FAILED = "EDIT_DEVICE_FAILED";

//  Device Status Change
export const DEVICE_STATUS_CHANGE_REQUESTED = "DEVICE_STATUS_CHANGE_REQUESTED";
export const DEVICE_STATUS_CHANGE_SUCCESS = "DEVICE_STATUS_CHANGE_SUCCESS";
export const DEVICE_STATUS_CHANGE_FAILED = "DEVICE_STATUS_CHANGE_FAILED";

// Delete device
export const DELETE_DEVICE_REQUESTED = "DELETE_DEVICE_REQUESTED";
export const DELETE_DEVICE_SUCCESS = "DELETE_DEVICE_SUCCESS";
export const DELETE_DEVICE_FAILED = "DELETE_DEVICE_FAILED";

// RESET ERROR MESSAGE
export const ADD_DEVICE_ERROR_RESET_REQUESTED = "ADD_DEVICE_ERROR_RESET_REQUESTED";

// CHANGE SEARCH
export const CHANGE_DEVICES_SEARCH_REQUESTED = "CHANGE_DEVICES_SEARCH_REQUESTED";

// CHANGE MODEL DEVICE SEARCH
export const CHANGE_MODEL_DEVICES_SEARCH_REQUESTED = "CHANGE_MODEL_DEVICES_SEARCH_REQUESTED";