import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../api/api_instance";

async function getApi(data) {
    try {
        const result = instance({
            url: `v1/model/${data.model_id}/anaglyph/parts/${data.part_id}/notes/${data.note_id}`,
            method: "GET",
            data:data,
        }).then((response) => {
            return response;
        })
        return await result;
    } catch (error) {
        throw error;
    }
}

function* fetchPartNotes(action) {
    try {
        const res = yield call(getApi, action.payload);
        yield put({ type: 'GET_PART_NOTE_DETAILS_SUCCESS', partNoteDetails: res.data });
    } catch (e) {
        if (e.response.status === 406 || e.response.status === 404 || e.response.status === 422) {
            yield put({ type: "GET_PART_NOTE_DETAILS_FAILED", message: e.response.data });
        } else {
            yield put({ type: "GET_PART_NOTE_DETAILS_FAILED", message: "Some error occurred" });
        }
    }
}

function* getPartNoteDetailsSaga() {
    yield takeEvery('GET_PART_NOTE_DETAILS_REQUESTED', fetchPartNotes);
}

export default getPartNoteDetailsSaga;