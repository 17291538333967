//  GET ALL TASK TYPES
export const GET_ALL_TASK_TYPES_REQUESTED = "GET_ALL_TASK_TYPES_REQUESTED";
export const GET_ALL_TASK_TYPES_SUCCESS = 'GET_ALL_TASK_TYPES_SUCCESS';
export const GET_ALL_TASK_TYPES_FAILED = 'GET_ALL_TASK_TYPES_FAILED';


// CREATE A TASK TYPE
export const ADD_TASK_TYPE_REQUESTED = 'ADD_TASK_TYPE_REQUESTED';
export const ADD_TASK_TYPE_SUCCESS = 'ADD_TASK_TYPE_SUCCESS';
export const ADD_TASK_TYPE_FAILED = 'ADD_TASK_TYPE_FAILED';


// GET A TASK TYPE DETAILS
export const TASK_TYPE_DETAILS_REQUESTED = 'TASK_TYPE_DETAILS_REQUESTED';
export const TASK_TYPE_DETAILS_SUCCESS = 'TASK_TYPE_DETAILS_SUCCESS';
export const TASK_TYPE_DETAILS_FAILED = 'TASK_TYPE_DETAILS_FAILED';


// UPDATE A TASK TYPE
export const UPDATE_TASK_TYPE_REQUESTED = 'UPDATE_TASK_TYPE_REQUESTED';
export const UPDATE_TASK_TYPE_SUCCESS = 'UPDATE_TASK_TYPE_SUCCESS';
export const UPDATE_TASK_TYPE_FAILED = 'UPDATE_TASK_TYPE_FAILED';


// DELETE A TASK TYPE
export const DELETE_TASK_TYPE_REQUESTED = 'DELETE_TASK_TYPE_REQUESTED';
export const DELETE_TASK_TYPE_SUCCESS = 'DELETE_TASK_TYPE_SUCCESS';
export const DELETE_TASK_TYPE_FAILED = 'DELETE_TASK_TYPE_FAILED';

// SET ADD TASK TYPE MODAL
export const SET_ADD_TASK_TYPE_MODAL = 'SET_ADD_TASK_TYPE_MODAL';

// SET_ADD_TASK_ERROR_MESSAGE
export const SET_ADD_TASK_ERROR_MESSAGE = 'SET_ADD_TASK_ERROR_MESSAGE';

// CHANGE SEARCH
export const CHANGE_TASK_TYPES_SEARCH_REQUESTED = "CHANGE_TASK_TYPES_SEARCH_REQUESTED";