// GET ALL WRITTEN ISSUES
export const GET_ALL_WRITTEN_ISSUES_REQUESTED = 'GET_ALL_WRITTEN_ISSUES_REQUESTED';
export const GET_ALL_WRITTEN_ISSUES_SUCCESS = 'GET_ALL_WRITTEN_ISSUES_SUCCESS';
export const GET_ALL_WRITTEN_ISSUES_FAILED = 'GET_ALL_WRITTEN_ISSUES_FAILED';


// ADD A WRITTEN ISSUES
export const ADD_WRITTEN_ISSUE_REQUESTED = 'ADD_WRITTEN_ISSUE_REQUESTED';
export const ADD_WRITTEN_ISSUE_SUCCESS = 'ADD_WRITTEN_ISSUE_SUCCESS';
export const ADD_WRITTEN_ISSUE_FAILED = 'ADD_WRITTEN_ISSUE_FAILED';


// GET  WRITTEN ISSUE DETAILS
export const WRITTEN_ISSUE_DETAILS_REQUESTED = 'WRITTEN_ISSUE_DETAILS_REQUESTED';
export const WRITTEN_ISSUE_DETAILS_SUCCESS = 'WRITTEN_ISSUE_DETAILS_SUCCESS';
export const WRITTEN_ISSUE_DETAILS_FAILED = 'WRITTEN_ISSUE_DETAILS_FAILED';


// EDIT / UPDATE WRITTEN ISSUE
export const UPDATE_WRITTEN_ISSUE_REQUESTED = 'UPDATE_WRITTEN_ISSUE_REQUESTED';
export const UPDATE_WRITTEN_ISSUE_SUCCESS = 'UPDATE_WRITTEN_ISSUE_SUCCESS';
export const UPDATE_WRITTEN_ISSUE_FAILED = 'UPDATE_WRITTEN_ISSUE_FAILED';


// DELETE A WRITTEN ISSUE
export const DELETE_WRITTEN_ISSUE_REQUESTED = 'DELETE_WRITTEN_ISSUE_REQUESTED';
export const DELETE_WRITTEN_ISSUE_SUCCESS = 'DELETE_WRITTEN_ISSUE_SUCCESS';
export const DELETE_WRITTEN_ISSUE_FAILED = 'DELETE_WRITTEN_ISSUE_FAILED';



// WRITTEN ISSUE STEPS =======================================================================

// ADD WRITTEN ISSUE STEP
export const ADD_WRITTEN_ISSUE_STEP_REQUESTED = 'ADD_WRITTEN_ISSUE_STEP_REQUESTED';
export const ADD_WRITTEN_ISSUE_STEP_SUCCESS = 'ADD_WRITTEN_ISSUE_STEP_SUCCESS';
export const ADD_WRITTEN_ISSUE_STEP_FAILED = 'ADD_WRITTEN_ISSUE_STEP_FAILED';

// UPDATE WRITTEN ISSUE STEP
export const UPDATE_WRITTEN_ISSUE_STEP_REQUESTED = 'UPDATE_WRITTEN_ISSUE_STEP_REQUESTED';
export const UPDATE_WRITTEN_ISSUE_STEP_SUCCESS = 'UPDATE_WRITTEN_ISSUE_STEP_SUCCESS';
export const UPDATE_WRITTEN_ISSUE_STEP_FAILED = 'UPDATE_WRITTEN_ISSUE_STEP_FAILED';

// DELETE WRITTEN ISSUE STEP
export const DELETE_WRITTEN_ISSUE_STEP_REQUESTED = 'DELETE_WRITTEN_ISSUE_STEP_REQUESTED';
export const DELETE_WRITTEN_ISSUE_STEP_SUCCESS = 'DELETE_WRITTEN_ISSUE_STEP_SUCCESS';
export const DELETE_WRITTEN_ISSUE_STEP_FAILED = 'DELETE_WRITTEN_ISSUE_STEP_FAILED';

// UPDATE WI STEP ORDER
export const UPDATE_WRITTEN_ISSUE_STEP_ORDER_REQUESTED = 'UPDATE_WRITTEN_ISSUE_STEP_ORDER_REQUESTED';
export const UPDATE_WRITTEN_ISSUE_STEP_ORDER_SUCCESS = 'UPDATE_WRITTEN_ISSUE_STEP_ORDER_SUCCESS';
export const UPDATE_WRITTEN_ISSUE_STEP_ORDER_FAILED = 'UPDATE_WRITTEN_ISSUE_STEP_ORDER_FAILED';

// SET DEFAULT STEP
export const SET_TO_DEFAULT_STEP_REQUESTED = 'SET_TO_DEFAULT_STEP_REQUESTED';
export const SET_TO_DEFAULT_STEP_SUCCESS = 'SET_TO_DEFAULT_STEP_SUCCESS';
export const SET_TO_DEFAULT_STEP_FAILED = 'SET_TO_DEFAULT_STEP_FAILED';


// WRITTEN ISSUES MODAL
export const SET_WRITTEN_ISSUES_MODAL = "SET_WRITTEN_ISSUES_MODAL";

// WRITTEN ISSUE DETAIL MODAL
export const SET_WRITTEN_ISSUES_DETAIL_MODAL = "SET_WRITTEN_ISSUES_DETAIL_MODAL";

// RESET WRITTEN ISSUES ERRORS
export const RESET_WRITTEN_ISSUES_ERRORS_REQUESTED = "RESET_WRITTEN_ISSUES_ERRORS_REQUESTED";

// CHANGE SEARCH
export const CHANGE_WRITTEN_ISSUES_SEARCH_REQUESTED = "CHANGE_WRITTEN_ISSUES_SEARCH_REQUESTED";